import React, { ReactNode } from "react";
import "./styles.scss";

interface LayoutDefaultProps {
  children: ReactNode;
  className?: string;
}

const LayoutDefault: React.FC<LayoutDefaultProps> = (props) => {
  const { children, className } = props;
  return (
    <div id="layout-default" className={className}>
      {children}
    </div>
  );
};

export default LayoutDefault;
