import React from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ListItem from "../../components/Lists/ListItem";
import ClockIconGray from "../../assets/icons/clock-icon-gray.svg";
import PrivacyIconGray from "../../assets/icons/privacy-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useUserInfo } from "../../hooks/userInfo";
import DeleteMembershipUserApi from "../../api/deleteMembershipApplicationAdmin";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useNavigate } from "react-router-dom";

const UserSettingsManageAccountPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { component, setComponent } = useLoginSignUpNavigation();
  const navigate = useNavigate();
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const takeABreakHandler = () => {};
  const blockRequestsHandler = () => {};
  const deleteAccountHandler = async () => {
    let phone = userInfo.phone.replace("+", "");

    if (
      phone === "5511967700452" ||
      phone === "14074464664" ||
      phone === "13053704780" ||
      phone === "5545999640276" ||
      phone === "5511914013654" ||
      phone === "5519984484218"
    ) {
      try {
        const deleteResponse = await DeleteMembershipUserApi(phone);
        if (deleteResponse.status === 204) {
          navigate("/app");
          localStorage.clear();
          sessionStorage.clear();
        } else {
          console.error(
            "Unexpected response from delete operation",
            deleteResponse
          );
        }
      } catch (error) {
        console.error("There was an error during the delete operation:", error);
      }
    }
  };

  return (
    <div className="user-settings-manage-account">
      <div>
        <PageTitle
          title={"Manage Account"}
          subtitle={"Account Security"}
          hasCloseIcon={false}
          onBackClick={() => setPrincipalScreen("/user-settings-account")}
        />

        <div className="list-container-settings">
          <ListItem
            showLabel={true}
            labelContent="Account"
            showDescription
            descriptionContent={"Deactivate my account for 7 days"}
            showIconLeft={true}
            iconLeftContent={ClockIconGray}
            showIconBorderLeft={true}
            showIconRight={true}
            showToggle
            onToggleChange={takeABreakHandler}
            showSeparator={true}
          />
          <ListItem
            showLabel={true}
            labelContent="Block Requests"
            showDescription
            descriptionContent={"Prohibit rose requests"}
            showIconLeft={true}
            iconLeftContent={PrivacyIconGray}
            showIconBorderLeft={true}
            showIconRight={true}
            showToggle
            onToggleChange={blockRequestsHandler}
          />
        </div>
      </div>
      <ButtonContainer>
        <Button buttonStyle="quaternary" onClick={deleteAccountHandler}>
          Delete Account
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default UserSettingsManageAccountPage;
