import React from "react";
import "./styles.scss";
import LockIcon from "../../../assets/icons/lock-icon-gray.svg";
import Button from "../../Buttons/Button";

interface CardsMediaLibraryProps {
  image: string;
  altImage: string;
  rosesPrice?: string | number;
  unlockClick?: (a: any) => void;
  isBlurred?: boolean;
}

const CardsMediaLibrary: React.FC<CardsMediaLibraryProps> = (props) => {
  const { image, altImage, rosesPrice, unlockClick, isBlurred } = props;

  return (
    <div id="cards-media-library" className={isBlurred ? "blurred" : ""}>
      <img className="principal-image" src={image} alt={altImage} />
      {isBlurred && (
        <div className="locked-section">
          <img src={LockIcon} alt="lock icon" />
          <p className="roses-price">{rosesPrice}</p>

          <Button onClick={unlockClick} buttonStyle="quintenary">
            unlock
          </Button>
        </div>
      )}

      {!isBlurred && <p className="infinity-symbol">∞</p>}
    </div>
  );
};

export default CardsMediaLibrary;
