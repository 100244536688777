import { useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import AccountVerification from "../../components/AccountVerifyNotification";
import ListItem from "../../components/Lists/ListItem";
import temporaryIcon from "../../assets/icons/camera-icon-gray.svg";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import accountIconGray from "../../assets/icons/account-icon-gray.svg";
import profileIconGray from "../../assets/icons/profile-icon-gray.svg";
import identityIconGray from "../../assets/icons/identity-icon-gray.svg";
import notificationsIconGray from "../../assets/icons/notification-icon-gray.svg";
import privacyIconGray from "../../assets/icons/privacy-icon-gray.svg";
import personalSafetyIconGray from "../../assets/icons/personal-icon-gray.svg";
import myOffersIconGray from "../../assets/icons/offers-icon-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsPage: React.FC = () => {
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  return (
    <div className="user-settings">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("")}
      />
      <AccountVerification
        labelContent={"Activate Account"}
        descriptionContent={
          "Select a subscription plan to activate your creator’s account"
        }
        showDisclaimer={true}
        disclaimerContent={`Your profile will only be visible to other’s once
					you have activated your account.`}
      />
      <div className="list-container-settings">
        <ListItem
          showLabel={true}
          labelContent="Account"
          showIconLeft={true}
          iconLeftContent={accountIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("/user-settings-account")}
        />
        <ListItem
          showLabel={true}
          labelContent="Profile"
          showIconLeft={true}
          iconLeftContent={profileIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("/user-settings-profile")}
        />
        <ListItem
          showLabel={true}
          labelContent="Identity Verification"
          showIconLeft={true}
          iconLeftContent={identityIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("")}
        />
        <ListItem
          showLabel={true}
          labelContent="Notifications"
          showIconLeft={true}
          iconLeftContent={notificationsIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("")}
        />
        <ListItem
          showLabel={true}
          labelContent="Privacy"
          showIconLeft={true}
          iconLeftContent={privacyIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("")}
        />
        {/* <ListItem
          showLabel={true}
          labelContent="Personal Safety"
          showIconLeft={true}
          iconLeftContent={personalSafetyIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("")}
        /> */}
        <ListItem
          showLabel={true}
          labelContent="My Offers"
          showIconLeft={true}
          iconLeftContent={myOffersIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          clickAction={() => setPrincipalScreen("")}
        />
      </div>
    </div>
  );
};

export default UserSettingsPage;
