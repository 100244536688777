import { servicesApi } from './services-api';

const MembershipUserInfoByPhoneApi = (phone: string) => {
  const apiRoute = `${servicesApi('applications')}/by-phone/${phone}`;

  return fetch(apiRoute, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {

      return response.json();
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
};

export default MembershipUserInfoByPhoneApi;
