let base_url_dev = "https://roses-backend-ovqi6xzbwa-ue.a.run.app";
let base_url_prod = "https://roses-backend-hsk6gjckza-ue.a.run.app";
let base_url_images =
  "https://us-east1-roses--development.cloudfunctions.net/upload-files";

const currentURL = base_url_dev;

export const servicesAPI = {
  wpp: `${currentURL}/wpp/send`,
  wppVerify: `${currentURL}/wpp/verify`,
  sms: `${currentURL}/sms/send`,
  smsVerify: `${currentURL}/sms/verify`,
  applications: `${currentURL}/membership-applications`,
  userInfoPhone: `${currentURL}/user`,
  applicationUserInfo: `${currentURL}/membership-applications/`,
  adminDashboard: `${currentURL}/membership-applications`,
  ipinfo: `${currentURL}/ipinfo`,
  image: `${base_url_images}`,
  getImages: `${currentURL}/membership-applications/photo/`,
  login: `${currentURL}/auth/login`,
  userRegister: `${currentURL}/auth`,
  users: `${currentURL}/user/match`,
  contact: `${currentURL}/contact`,
  chat: `${currentURL}/chat`,
  user: `${currentURL}/user/self-detail`,
  chatMessages: `${currentURL}/chat/list`,
  mvpTests: `${currentURL}/mvp-tests`,
  newMembershipRegister: `${currentURL}/membership-applications/register`,
  userDetail: `${currentURL}/user/public-detail`,
} as const;

type ServiceType = keyof typeof servicesAPI;

export const servicesApi = <T extends ServiceType>(
  service: T
): (typeof servicesAPI)[T] => {
  return servicesAPI[service];
};
