import { servicesApi } from "./services-api";

export type LogIn = ReturnType<ReturnType<typeof UserAuth>["postUserAuth"]>;

const UserAuth = () => {
  const apiRoute = servicesApi("login");
  const postUserAuth = async (data: { phone: string; password: string }) => {
    let response = await fetch(`${apiRoute}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  };

  return {
    postUserAuth,
  };
};

export default UserAuth();
