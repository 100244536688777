import { useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import EyeIcon from "../../assets/icons/eye-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import Toast from "../../components/Toast";

const CreateNamePage: React.FC = () => {
  const [hasError, setHasError] = useState(false);
  const [userName, setUsername] = useState("");
  const [isUserNameInputWarning, setIsUserNameInputWarning] = useState(false);
  const { setComponent } = useLoginSignUpNavigation();
  const { setUserInfo } = useUserInfo();

  const addNameSubmit = () => {
    if (userName.length < 3) {
      setHasError(true);
      setIsUserNameInputWarning(true);

      setTimeout(() => {
        setIsUserNameInputWarning(false);

        setHasError(false);
      }, 4000);
    } else {
      setUserInfo("user_name", userName);
      setComponent("/send-photos");
    }
  };

  const handleGoBack = () => {
    setComponent("/account-type");
  };

  return (
    <LayoutDefault className="create-name">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => handleGoBack()}
          title="Display name"
          subTitle={`We safeguard our community by ensuring the authenticity of 
					every member on our platform, upholding a standard of trust and 
					transparency.`}
        />
        <InputText
          value={userName}
          placeholder="Display Name"
          onChangeInput={(e) => setUsername(e)}
          isWarning={isUserNameInputWarning}
        />
        <SubText icon={EyeIcon} altIcon="clock icon">
          This will be shown on your profile
        </SubText>
      </div>
      <ButtonContainer>
        <Button buttonStyle="primary" onClick={addNameSubmit}>
          Continue
        </Button>
      </ButtonContainer>
      <Toast
        type="error"
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
        description="The display name must be at least three letters long."
      />
    </LayoutDefault>
  );
};

export default CreateNamePage;
