import React from "react";
import { useLanguage } from "../../../contexts/languageContext";

interface TranslatableTextProps {
  textKey: string;
}

const TranslatableText: React.FC<TranslatableTextProps> = ({ textKey }) => {
  const { translations } = useLanguage();

  const [page, key] = textKey.split("/");
  const pageId = parseInt(page);
  const pageContent = translations.pages.find((p) => p.id === pageId)?.content;

  if (pageContent && key in pageContent) {
    return <div dangerouslySetInnerHTML={{ __html: pageContent[key] ?? "" }} />;
  } else {
    console.error(`Invalid textKey: ${textKey}`);
    return null;
  }
};

export default TranslatableText;
