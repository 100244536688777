import FooterLP from "../../../components/Footers/FooterLP";
import HeaderLandingPage from "../../../components/Headers/HeaderLandingPage";
import NavBar from "../../../components/NavBar";
import LayoutDefault from "../../../components/Wrappers/LayoutDefault";
import LayoutTermsPagesSite from "../../../components/Wrappers/LayoutTermsPagesSite";
import LayoutWebSite from "../../../components/Wrappers/LayoutWebSite";
import "./styles.scss";

const USCPage: React.FC = () => {
  return (
    <>
      <NavBar />
      <LayoutTermsPagesSite className="usc-page">
        <section id="USC-2257" className="usc-wrapper wrapper padding-top">
          <div className="usc-wrapper-inner inner">
            <h1 textkey="8/uscTitle">
              <b>USC</b> 2257
            </h1>
            <hr />
            <p textkey="8/uscDescription">
              Roses.Vip is fully compliant with 18 U.S.C. 2257 and all
              associated regulations. All models, actors, actresses, and other
              persons appearing in any visual depiction of actual sexually
              explicit conduct appearing or otherwise contained in this website
              were over the age of eighteen years at the time of the creation of
              such depictions. Records required to be maintained pursuant to 18
              U.S.C. 2257 are kept by the custodian of records, who can be
              reached at 600 N BROAD STREET SUITE 5 #3977, MIDDLETOWN, DE,
              19709.
            </p>
          </div>
        </section>
        <FooterLP />
      </LayoutTermsPagesSite>
    </>
  );
};

export default USCPage;
