import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import InputText from "../../components/Inputs/InputText";

import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import InfoIcon from "../../assets/icons/union-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useState } from "react";
import SubText from "../../components/Texts/SubText";

const UserSettingsAppLanguagePage: React.FC = () => {
  const [residence, setResidence] = useState("Brazil");
  const [language, setLanguage] = useState("English");

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  return (
    <div className="user-settings-language">
      <div>
        <PageTitle
          title={"Language & Location"}
          subtitle={"Preferences"}
          hasCloseIcon={false}
          onBackClick={() => setPrincipalScreen("/user-settings-account")}
        />
        <InputText
          label="Legal Residence"
          iconRight={chevronRightIcon}
          value={residence}
          onChangeInput={() => {}}
        />
        <SubText icon={InfoIcon} altIcon="info icon">
          If you want to change your country, please contact customer support
        </SubText>
        <InputText
          label="Default Language"
          iconRight={chevronRightIcon}
          value={language}
          onChangeInput={() => {}}
        />
      </div>
    </div>
  );
};

export default UserSettingsAppLanguagePage;
