import { useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { ClipLoader } from "react-spinners";
import postUserAuth from "../../api/postUserAuth";
import Toast from "../../components/Toast";
import { useNavigate } from "react-router-dom";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import * as jwtDecode from "jwt-decode";

const PasswordSignInPage: React.FC = () => {
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [toastErrorMsg, setToastErrorMsg] = useState("");
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  const navigate = useNavigate();

  const loginSubmit = async () => {
    setIsLoading(true);

    const prepareData = {
      phone: userInfo.phone.split("+")[1],
      password: userPassword,
    };

    let responseAuth = await postUserAuth.postUserAuth(prepareData);

    switch (responseAuth.status) {
      case 200:
        let response = await responseAuth.json();
        let access_token = response.access_token;
        const userInfo = (await jwtDecode.jwtDecode(access_token)) as any;

        console.log(userInfo);
        setUserInfo("access_token", access_token);
        setUserInfo("user_name", userInfo?.validUser.displayname);
        setUserInfo("account_type", userInfo?.validUser.role);
        setUserInfo("phone", userInfo?.validUser.phone);
        setUserInfo("user_email", userInfo?.validUser.email);
        setUserInfo("user_id", userInfo?.validUser.userId);

        setUserInfo("access_token", access_token);

        navigate("/home");

        break;
      case 401:
        setHasError(true);
        setIsWarning(true);
        setToastErrorMsg("Check your credentials and, please,  try again");

        setTimeout(() => {
          setIsWarning(false);
          setHasError(false);
        }, 4000);
        break;
    }

    setIsLoading(false);
  };

  const cancelSubmit = () => {
    navigate("/");
  };

  return (
    <LayoutDefault className="password-sign-in-page">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => setComponent("/create-number-verify")}
          title={
            <>
              Enter your
              <br />
              password
            </>
          }
          subTitle={
            <>
              Your password should be at least <br /> 8 characters long
            </>
          }
        />
        <InputText
          inputType="password"
          value={userPassword}
          placeholder="password"
          onChangeInput={(e) => setUserPassword(e)}
          isWarning={isWarning}
        />
        <SubText icon={LockIcon} altIcon="clock icon">
          Never share your credentials with anyone. Forgot your password?
          <a href="/"> Reset Password</a>
        </SubText>
      </div>

      <ButtonContainer>
        <Button
          disabled={userPassword.length < 8 || isLoading}
          buttonStyle="primary"
          onClick={loginSubmit}
        >
          {isLoading ? (
            <ClipLoader color={"#FFF"} loading={true} size={15} />
          ) : (
            "Login"
          )}
        </Button>
        <Button buttonStyle="quaternary" onClick={cancelSubmit}>
          Cancel
        </Button>
      </ButtonContainer>
      <Toast
        type="error"
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
        description={toastErrorMsg}
      />
    </LayoutDefault>
  );
};

export default PasswordSignInPage;
