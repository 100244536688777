import React from "react";
import InitialImage from "../../assets/images/353021aa9d1256ec3d20d9d33e3a10e2.jpeg";
import Button from "../../components/Buttons/Button";
import RosesLogo from "../../assets/icons/roses-default.svg";
import SubText from "../../components/Texts/SubText";
import "./styles.scss";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";

const WelcomeUser: React.FC = () => {
  const { userInfo } = useUserInfo();

  const { setComponent } = useLoginSignUpNavigation();

  const handleConfirm = () => {
    if (userInfo.account_type.toLocaleLowerCase() === "creator") {
      setComponent("/create-legal-name");
    } else {
      setComponent("/create-password");
    }
  };

  return (
    <div className="welcome-user">
      <div
        style={{ backgroundImage: `url(${InitialImage})` }}
        className="welcome-roses"
      />

      <div className="bottom-text-button-container">
        <img className="roses-logo" src={RosesLogo} alt="Initial Steplogo" />
        <h1 className="confirmation">Welcome to Roses!</h1>
        <SubText>
          Your membership application has been accepted. Complete your account
          setup to start exploring Roses and connecting with other members.
        </SubText>

        <Button buttonStyle="primary" onClick={handleConfirm}>
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default WelcomeUser;
