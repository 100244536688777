import React, { ReactNode } from "react";
import { AuthProvider } from "./auth";
import { PwaProvider } from "./pwa";
import { DeskNavigationProvider } from "./useDeskNavigation";
import { LoginSignUpProvider } from "./useLoginSignUpNavigation";
import { UserInfoProvider } from "./userInfo";

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <PwaProvider>
      <AuthProvider>
        <DeskNavigationProvider>
          <UserInfoProvider>
            <LoginSignUpProvider>{children}</LoginSignUpProvider>
          </UserInfoProvider>
        </DeskNavigationProvider>
      </AuthProvider>
    </PwaProvider>
  );
};

export default AppProvider;
