import React from "react";

import "./styles.scss";

interface IChatMessage {
  side: string;
  message?: string;
  time?: string;
  translateDisclaimerContent?: string;
  countryFlag?: string;
  key?: string;
}

const ChatMessage: React.FC<IChatMessage> = ({
  side,
  message,
  time,
  translateDisclaimerContent,
  countryFlag,
}: IChatMessage) => {
  const messageClass = `chat-message ${side}`;
  const containerClass = `chat-message__container ${side}`;

  return (
    <div>
      {/* {side === "left" && <img className="flag" src={countryFlag} />} */}
      {side === "right" && (
        <p className="translate-disclaimer translate-disclaimer-right">
          {translateDisclaimerContent}
        </p>
      )}

      <div className={containerClass}>
        <div className={messageClass}>
          <p className="chat-message__content">{message}</p>
          <p className="chat-time">{time}</p>
        </div>
      </div>
      {side === "left" && (
        <p className="translate-disclaimer">{translateDisclaimerContent}</p>
      )}
      <div className="flag-image-container">
        {side === "right" && <img className="flag-right" src={countryFlag} />}
      </div>
    </div>
  );
};

export default ChatMessage;
