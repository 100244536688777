import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";
import HeaderHomePage from "../../Headers/HeaderHomePage";
import CardsCatalog from "../../Cards/CardsCatalog";
import { useDeskNavigation } from "../../../hooks/useDeskNavigation";
import Stories from "../../Stories";
import storiesMock from "../../../mocks/stories";

interface DeskProps {
  principalMain?: ReactNode;
  secondaryMain?: ReactNode;
  tertiaryMain?: ReactNode;
  catalogMain?: ReactNode;
  clickStory: (a: any) => void;
  catalogHandlerCallback: (a: any) => void;
  clickProfileAvatar: (a: any) => void;
  profileAvatar?: string;
  hasANewMessage: boolean;
  onChangeInputSearch: (a: string) => void;
  valueInputSearch: string;
}

const Desk: React.FC<DeskProps> = ({
  hasANewMessage,
  onChangeInputSearch,
  profileAvatar,
  valueInputSearch,
  principalMain,
  secondaryMain,
  tertiaryMain,
  catalogMain,
  clickStory,
  catalogHandlerCallback,
  clickProfileAvatar,
}) => {
  const [isPrincipalMainVisible, setIsPrincipalMainVisible] = useState(false);
  const [isSecondaryMainVisible, setIsSecondaryMainVisible] = useState(false);
  const [isTertiaryMainVisible, setIsTertiaryMainVisible] = useState(false);
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [isPrincipalMainVisible, isSecondaryMainVisible, isTertiaryMainVisible]);

  useEffect(() => {
    if (principalMain) {
      setIsPrincipalMainVisible(true);
    } else {
      setIsPrincipalMainVisible(false);
    }
  }, [principalMain]);

  useEffect(() => {
    if (secondaryMain) {
      setIsSecondaryMainVisible(true);
    } else {
      setIsSecondaryMainVisible(false);
    }
  }, [secondaryMain]);

  useEffect(() => {
    if (tertiaryMain) {
      setIsTertiaryMainVisible(true);
    } else {
      setIsTertiaryMainVisible(false);
    }
  }, [tertiaryMain]);

  const openChatListHandler = () => {
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/chat-list");
  };

  return (
    <div id="desk-wrapper">
      <HeaderHomePage
        valueInputSearch={valueInputSearch}
        hasANewMessage={hasANewMessage}
        onChangeInputSearch={onChangeInputSearch}
        profileAvatar={profileAvatar}
        headerIcon="roses-logo"
        title="Roses™️"
        clickProfileAvatar={clickProfileAvatar}
        clickChatList={openChatListHandler}
        className={
          isPrincipalMainVisible ||
          isSecondaryMainVisible ||
          isTertiaryMainVisible
            ? "hidden-component"
            : ""
        }
      />

      <main
        className={`main-page-desk ${
          isPrincipalMainVisible || isSecondaryMainVisible
            ? `space-without-header ${
                isTertiaryMainVisible ? "grid-template-changed" : ""
              }`
            : ""
        }`}
      >
        <div
          className={`catalog-main ${
            isSecondaryMainVisible &&
            isTertiaryMainVisible &&
            isPrincipalMainVisible
              ? "hide-catalog-for-gallery"
              : isPrincipalMainVisible && !isSecondaryMainVisible
              ? "hide-catalog"
              : isSecondaryMainVisible && isPrincipalMainVisible
              ? "secondary-hide-catalog"
              : isSecondaryMainVisible && !isPrincipalMainVisible
              ? "only-secondary-main-is-show"
              : "show-catalog"
          }`}
        >
          <Stories
            storiesImages={storiesMock.map((story, index) => ({
              img: story.url,
              userId: `user${index}`,
              wasSeen: index < 6,
            }))}
            clickStory={clickStory}
            className="catalog-desk"
          />
          {catalogMain || (
            <CardsCatalog
              catalogHandlerCallback={catalogHandlerCallback}
              className="catalog-component-main"
            />
          )}
        </div>

        <div
          className={`principal-main ${
            isPrincipalMainVisible && !isSecondaryMainVisible
              ? "show-principal-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                !isTertiaryMainVisible
              ? "show-principal-main show-principal-and-secondary-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-principal-main hide-principal-main-mobile"
              : "hide-principal-main"
          }`}
        >
          {principalMain}
        </div>

        <div
          className={`secondary-main ${
            isSecondaryMainVisible && !isTertiaryMainVisible
              ? "show-secondary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                isPrincipalMainVisible
              ? "show-secondary-main show-secondary-and-tertiary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                !isPrincipalMainVisible
              ? "show-secondary-main only-secondary-and-tertiary-main-show"
              : "hide-secondary-main"
          }`}
        >
          {secondaryMain}
        </div>

        <div
          className={`tertiary-main ${
            isTertiaryMainVisible &&
            isSecondaryMainVisible &&
            isPrincipalMainVisible
              ? "show-tertiary-main"
              : !isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-all-main"
              : "hide-tertiary-main"
          }`}
        >
          {tertiaryMain}
        </div>
      </main>

      {/* <div className="footer-desk">{bottomChildren}</div> */}
    </div>
  );
};

export default Desk;
