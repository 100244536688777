import React, { ReactNode } from "react";
import "./styles.scss";
import LogoRosesIconPink from "../../../assets/icons/logos/logo-rose-icon-pink.svg";
import LeftArrow from "../../../assets/icons/navigationIcons/arrow-left-gray.svg";
import Bell from "../../../assets/icons/bell-pink.svg";
import Heart from "../../../assets/icons/heartIcons/heart-pink.svg";
import PinIcon from "../../../assets/icons/location-pink.svg";
import PlusIconCircle from "../../../assets/icons/plus-circle-icon-gray.svg";
import Ballon from "../../../assets/icons/ballon-icon-gray.svg";
import FilterIcon from "../../../assets/icons/iconfilter.svg";
import TitleText from "../../Texts/TitleText";
import InputText from "../../Inputs/InputText";
import ProfileIcon from "../../../assets/icons/profile-icon-gray.svg";
import HeartIconGray from "../../../assets/icons/heartIcons/heart-outline-gray.svg";
import GridSquarePink from "../../../assets/icons/grid-square-pink.svg";

interface HeaderHomePageProps {
  title?: ReactNode;
  headerIcon?: "back-button" | "roses-logo" | "bell" | "heart" | "pin-icon";
  backButtonClick?: React.MouseEventHandler<HTMLImageElement>;
  valueInputSearch: string;
  onChangeInputSearch: (a: string) => void;
  hasANewMessage: boolean;
  profileAvatar?: string;
  clickProfileAvatar?: (a: any) => void;
  className?: string;
  clickChatList?: (a: any) => void;
}

const HeaderHomePage: React.FC<HeaderHomePageProps> = ({
  title,
  headerIcon,
  backButtonClick,
  profileAvatar,
  valueInputSearch,
  onChangeInputSearch,
  hasANewMessage,
  clickProfileAvatar,
  className,
  clickChatList,
}) => {
  const iconMap = {
    "back-button": LeftArrow,
    "roses-logo": LogoRosesIconPink,
    bell: Bell,
    heart: Heart,
    "pin-icon": PinIcon,
  };

  const icon = headerIcon ? iconMap[headerIcon] : "";

  return (
    <header id="header-home-page" className={className}>
      {/* MOBILE */}
      <div className="header-mobile-container">
        <div className="icon-container">
          <img
            src={icon}
            alt="Header Icon"
            onClick={headerIcon === "back-button" ? backButtonClick : undefined}
          />
          <div className="actions-container">
            <img src={PlusIconCircle} alt="plus icon" />
            <div className="new-message-container">
              <img src={Ballon} alt="message icon" onClick={clickChatList} />
              {hasANewMessage && <span className="sphere-icon"></span>}
            </div>
            <div
              className={`profile-wrapper ${
                profileAvatar ? "user-logged" : ""
              }`}
              onClick={clickProfileAvatar}
            >
              <img
                className="storyImage"
                src={profileAvatar ? profileAvatar : ProfileIcon}
                alt="stories"
              />
            </div>
          </div>
        </div>

        <InputText
          value={valueInputSearch}
          searchInput
          onChangeInput={onChangeInputSearch}
          placeholder="Search"
          inputStyle="input-text-search"
          iconRight={FilterIcon}
        />
      </div>
      {/* DESKTOP */}
      <div className="header-wide-container">
        <div className="icon-container">
          {headerIcon && (
            <img
              src={icon}
              alt="Header Icon"
              onClick={
                headerIcon === "back-button" ? backButtonClick : undefined
              }
            />
          )}
          <TitleText>{title}</TitleText>
        </div>
        <div className="grid-heart-buttons-container">
          <img src={GridSquarePink} alt="grid square pink" />
          <img src={HeartIconGray} alt=" heart icon gray" />
        </div>
        <InputText
          value={valueInputSearch}
          searchInput
          onChangeInput={onChangeInputSearch}
          placeholder="Search"
          inputStyle="input-text-search"
          iconRight={FilterIcon}
        />

        <div className="actions-container">
          <div className="new-message-container">
            <img src={Ballon} alt="message icon" onClick={clickChatList} />
            {hasANewMessage && <span className="sphere-icon"></span>}
          </div>

          <div
            className={`profile-wrapper ${profileAvatar ? "user-logged" : ""}`}
            onClick={clickProfileAvatar}
          >
            <img
              className="storyImage"
              src={profileAvatar ? profileAvatar : ProfileIcon}
              alt="stories"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderHomePage;
