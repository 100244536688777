import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { IUserInfo } from "../types/userInfo";

interface IUserInfoContext {
  userInfo: IUserInfo;
  setUserInfo: (key: keyof IUserInfo, value: string) => void;
}

const UserInfoContext = createContext<IUserInfoContext | undefined>(undefined);

const UserInfoProvider = ({ children }: { children: ReactNode }) => {
  const [userInfo, setUserInfoState] = useState<IUserInfo>({
    phone: "",
    instagram: "",
    account_type: "",
    user_name: "",
    user_id: "",
    user_email: "",
    access_token: "",
  });

  const setUserInfo = (key: keyof IUserInfo, value: string) => {
    setUserInfoState((prevUserInfo) => ({
      ...prevUserInfo,
      [key]: value,
    }));
    if (key === "access_token") {
      sessionStorage.setItem("roses-access-token", value);
    } else {
      localStorage.setItem(key, value);
    }
  };

  useEffect(() => {
    setUserInfoState({
      access_token:
        userInfo.access_token ||
        sessionStorage.getItem("roses-access-token") ||
        "",
      account_type:
        userInfo.account_type || localStorage.getItem("account_type") || "",
      instagram: userInfo.instagram || localStorage.getItem("instagram") || "",
      phone: userInfo.phone || localStorage.getItem("phone") || "",
      user_email:
        userInfo.user_email || localStorage.getItem("user_email") || "",
      user_id: userInfo.user_id || localStorage.getItem("user_id") || "",
      user_name: userInfo.user_name || localStorage.getItem("user_name") || "",
    });
  }, [
    setUserInfoState,
    userInfo.access_token,
    userInfo.account_type,
    userInfo.instagram,
    userInfo.phone,
    userInfo.user_email,
    userInfo.user_id,
    userInfo.user_name,
  ]);

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};

const useUserInfo = () => {
  const context = useContext(UserInfoContext);

  if (context === undefined) {
    throw new Error("useUserInfo must be used within a UserInfoProvider");
  }

  return context;
};

export { UserInfoProvider, useUserInfo };
