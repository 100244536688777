import { servicesApi } from './services-api';

const MembershipUserInfoApi = (userId: string) => {
	const apiRoute = `${servicesApi('applicationUserInfo')}${userId}`;
	console.log('ROTA DO GET MEMBERSHIP', apiRoute);
	return fetch(apiRoute, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then(data => {
			return data;
		})
		.catch(error => {
			console.error('There was a problem with the fetch operation:', error);
		});
};

export default MembershipUserInfoApi;
