import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import InputText from "../../components/Inputs/InputText";
import SubText from "../../components/Texts/SubText";
import disclaimerIcon from "../../assets/icons/union-gray.svg";
import Button from "../../components/Buttons/Button";
import mapMock from "../../assets/icons/image-mock/map-mock.png";

const UserSettingsLocationPage: React.FC = () => {
  return (
    <LayoutDefault className="user-settings">
      <div>
        <PageTitle title={"Change Location"} subtitle={"Configuration"} />
        <InputText
          value={"My Current Location"}
          placeholder="Location"
          onChangeInput={() => {}}
        />
        <Button
          children={"Upgrade to change location"}
          buttonStyle={"primary"}
        />
        <SubText
          children={
            "Upgrade now to enjoy the flexibility of connecting with people from around the world and experience even more premium features."
          }
          icon={disclaimerIcon}
        />
        <img src={mapMock} alt="" />
      </div>
    </LayoutDefault>
  );
};

export default UserSettingsLocationPage;
