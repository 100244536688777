import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import Button from "../../components/Buttons/Button";
import CenterText from "../../components/Texts/CenterText";
import ButtonSafety from "../../components/Buttons/ButtonSafety";
import safetyGradientIcon from "../../assets/icons/safety-big-icon-gradient.svg";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";

const UserSettingsHoldSafePage: React.FC = () => {
  return (
    <LayoutDefault className="user-settings">
      <div>
        <PageTitle title={"Personal Safety"} subtitle={"Emergency Response"} />
        <CenterText
          icon={safetyGradientIcon}
          title="Personal Safety"
          titleFontSize="14px"
          subtitle={
            "Press and hold the button until safe. Release and enter your Private Key within 15 seconds, or we'll notify your emergency contacts and share your your latest activity."
          }
          subtitleFontSize="14px"
          imgHeight="40px"
          imgWidth="40px"
        />
        <div className="container-safe-button">
          <ButtonSafety />
        </div>
      </div>
      <ButtonContainer>
        <Button buttonStyle="quaternary" onClick={() => {}}>
          Cancel
        </Button>
      </ButtonContainer>
    </LayoutDefault>
  );
};

export default UserSettingsHoldSafePage;
