import React from "react";
import "./styles.scss";

import SubText from "../Texts/SubText";
import Button from "../Buttons/Button";
import disclaimerIcon from "../../assets/icons/union-gray.svg";

interface AccountVerificationProps {
  showVerifyIcon?: boolean;
  showVerifyButton?: boolean;
  showDisclaimer?: boolean;
  labelContent: string;
  descriptionContent: string;
  iconContent?: string;
  disclaimerContent?: string;
}

const AccountVerification: React.FC<AccountVerificationProps> = ({
  showVerifyIcon = false,
  showVerifyButton = false,
  showDisclaimer = false,
  labelContent = "Label",
  descriptionContent = "Description",
  disclaimerContent = "Disclaimer",
  iconContent,
}) => {
  return (
    <div className="account-verify-container">
      <div className="content-wrapper">
        <div className="text-wrapper">
          <div className="title-icon-wrapper">
            <div className="title">{labelContent}</div>
            {showVerifyIcon && <img src={iconContent} alt={"Verify Icon"} />}
          </div>
          <div className="description">{descriptionContent}</div>
        </div>
        {showVerifyButton && (
          <div className="verify-button-wrapper">
            <Button children={"Verify"} buttonStyle={"primary"} />
          </div>
        )}
      </div>
      {showDisclaimer && <div className="separator"></div>}
      {showDisclaimer && (
        <SubText children={disclaimerContent} icon={disclaimerIcon} />
      )}
    </div>
  );
};

export default AccountVerification;
