import React, { ReactNode } from "react";
import "./styles.scss";
import ListItem from "../../Lists/ListItem";

import ImageToggle from "../../Toggle/ImageToggle";
import ChatImageAnimation from "../../ChatImageAnimation";
import mapShieldImageOn from "../../../assets/images/landingPageImages/roses-location-shield-on.png";
import mapShieldImageOff from "../../../assets/images/landingPageImages/roses-location-shield-off.png";
import pixelShieldImageOn from "../../../assets/images/landingPageImages/roses-pixel-shield-on.png";
import pixelShieldImageOff from "../../../assets/images/landingPageImages/roses-pixel-shield-off.png";
import iconDigitalPrintGray from "../../../assets/icons/digital-print-icon-gray.svg";
import TranslatableText from "../../Texts/TranslatableText";

interface ContentWrapperProps {
  contentSide?: "left" | "right";
  listItemCount?: number;
  featureIcon: string;
  featureTitle: ReactNode;
  featureSubTitle: ReactNode;
  featureDescription: ReactNode;
  listItem1LabelContent?: ReactNode;
  listItem1DescriptionContent?: ReactNode;
  listItem2LabelContent?: ReactNode;
  listItem2DescriptionContent?: ReactNode;
  componentType?: "map" | "pixel" | "chat";
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  contentSide = "left",
  listItemCount = 1,
  featureIcon,
  featureTitle,
  featureSubTitle,
  featureDescription,
  listItem1LabelContent,
  listItem1DescriptionContent,
  listItem2LabelContent,
  listItem2DescriptionContent,
  componentType = "map",
}) => {
  const isLeft = contentSide === "left";
  const wrapperClass = isLeft ? "left-content" : "right-content";

  return (
    <div id="content-web-site-wrapper" className={wrapperClass}>
      {isLeft ? (
        <>
          <div className="yellow-div">
            <div className="text-container">
              <img className="title-icon" src={featureIcon} alt="Icon" />
              <h2>{featureTitle}</h2>
              <p>{featureSubTitle}</p>
              <span>by Roses™</span>
              <div className="feature-description">
                <p>{featureDescription}</p>
              </div>
              {listItemCount > 0 && (
                <ListItem
                  showSphereIcon
                  showLabel
                  labelContent={listItem1LabelContent}
                  showDescription
                  descriptionContent={listItem1DescriptionContent}
                />
              )}
              {listItemCount > 1 && <div className="divider"></div>}
              {listItemCount > 1 && (
                <ListItem
                  showSphereIcon
                  showLabel
                  labelContent={listItem2LabelContent || ""}
                  showDescription
                  descriptionContent={listItem2DescriptionContent || ""}
                />
              )}
            </div>
          </div>
          <div className="purple-div">
            {componentType === "map" && (
              <ImageToggle
                imageOn={mapShieldImageOn}
                imageOff={mapShieldImageOff}
                labelContent={
                  <TranslatableText textKey="5/locationListItemTitle" />
                }
                descriptionContent={
                  <TranslatableText textKey="5/locationListItemDescription" />
                }
                showIconLeft={false}
                showModal={true}
                borderRadiusSide="right"
              />
            )}
            {componentType === "pixel" && (
              <ImageToggle
                imageOn={pixelShieldImageOn}
                imageOff={pixelShieldImageOff}
                iconLeftContent={iconDigitalPrintGray}
                labelContent={
                  <TranslatableText textKey="5/identityListItemTitle" />
                }
                descriptionContent={
                  <TranslatableText textKey="5/identityListItemDescription" />
                }
                showIconLeft={true}
                borderRadiusSide="left"
              />
            )}
            {componentType === "chat" && <ChatImageAnimation />}
          </div>
        </>
      ) : (
        <>
          <div className="purple-div">
            {componentType === "map" && (
              <ImageToggle
                imageOn={mapShieldImageOn}
                imageOff={mapShieldImageOff}
                labelContent={
                  <TranslatableText textKey="5/locationListItemTitle" />
                }
                descriptionContent={
                  <TranslatableText textKey="5/locationListItemDescription" />
                }
                showIconLeft={false}
                showModal={true}
                borderRadiusSide="right"
              />
            )}
            {componentType === "pixel" && (
              <ImageToggle
                imageOn={pixelShieldImageOn}
                imageOff={pixelShieldImageOff}
                iconLeftContent={iconDigitalPrintGray}
                labelContent={
                  <TranslatableText textKey="5/identityListItemTitle" />
                }
                descriptionContent={
                  <TranslatableText textKey="5/identityListItemDescription" />
                }
                showIconLeft={true}
                borderRadiusSide="left"
              />
            )}
            {componentType === "chat" && <ChatImageAnimation />}
          </div>
          <div className="yellow-div">
            <div className="text-container">
              <img className="title-icon" src={featureIcon} alt="Icon" />
              <h2>{featureTitle}</h2>
              <p>{featureSubTitle}</p>
              <span>by Roses™</span>
              <div className="feature-description">
                <p>{featureDescription}</p>
              </div>
              {listItemCount > 0 && (
                <ListItem
                  showSphereIcon
                  showLabel
                  labelContent={listItem1LabelContent}
                  showDescription
                  descriptionContent={listItem1DescriptionContent}
                />
              )}
              {listItemCount > 1 && <div className="divider"></div>}
              {listItemCount > 1 && (
                <ListItem
                  showSphereIcon
                  showLabel
                  labelContent={listItem2LabelContent || ""}
                  showDescription
                  descriptionContent={listItem2DescriptionContent || ""}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContentWrapper;
