import { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import CodeVerification from "../../components/CodeVerification";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import ClockIcon from "../../assets/icons/clock-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { CountryCode } from "libphonenumber-js";
import { setupPhoneNumberInput } from "../../utils/masks";
import codesMock from "../../mocks/countryCodes.json";
import postVerifyPhone from "../../api/postVerifyPhoneWPP";
import Toast from "../../components/Toast";
import { useAuth } from "../../hooks/auth";
import { ClipLoader } from "react-spinners";
import UserInfoByPhoneApi from "../../api/getUsersInfoByPhone";
import MembershipUserInfoByPhoneApi from "../../api/getMembershipApplicationByPhone";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";

const CreateNumberVerifyPage: React.FC = () => {
  const [codeValue, setCodeValue] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [phoneWontPlus, setPhoneWontPlus] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isWarning, setIsWarning] = useState(false);
  const { preRegisterInfo, setPreRegisterInfo } = useAuth();
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  useEffect(() => {
    const country = codesMock.find(
      (code) => code.countryCode === preRegisterInfo?.countryCode
    );

    const formattedNumber = setupPhoneNumberInput(
      country?.code as CountryCode,
      userInfo.phone ?? ""
    );

    setFormattedNumber(formattedNumber);

    setPhoneWontPlus(userInfo.phone.split("+")[1] as string);
  }, [preRegisterInfo]);

  const handleVerificationSuccess = async () => {
    const response = await UserInfoByPhoneApi(phoneWontPlus);

    if (response.statusCode !== 404) {
      setComponent("/password-sign-in");
    } else {
      await handleMembershipInfo();
    }
  };

  const handleMembershipInfo = async () => {
    const response = await MembershipUserInfoByPhoneApi(phoneWontPlus);

    setUserInfo("user_id", response?.id);
    setUserInfo("user_name", response?.displayName);
    setUserInfo("account_type", response?.type);

    if (response.statusCode === 404) {
      localStorage.setItem("@from", "create-number-verify");

      setComponent("/membership");
    } else {
      if (response.isApproved && !response.isCompleted) {
        setComponent("/welcome-user");
      } else if (!response.isApproved) {
        setComponent("/wait-list-application");
      } else if (response.isApproved && response.isCompleted) {
        setComponent("/password-sign-in");
      }
    }
    setIsLoading(false);
  };

  const addPhoneNumberSubmit = async () => {
    setIsLoading(true);
    const response = await postVerifyPhone
      .createVerification(phoneWontPlus)
      .verifyCode(codeValue);

    switch (response.status) {
      case 200:
        const body = await response.json();
        if (body.valid) {
          await handleVerificationSuccess();
        } else {
          setHasError(true);

          setTimeout(() => setHasError(false), 4000);
        }
    }

    setIsLoading(false);
  };

  const resendCode = async () => {
    setIsLoading(true);

    const response = await postVerifyPhone
      .createVerification(phoneWontPlus)
      .requestCode();

    switch (response.status) {
      case 200:
        //TODO
        // Success to resend code
        break;
      default:
        setHasError(true);
        break;
    }

    setIsLoading(false);
  };

  const backButtonHandler = () => {
    setComponent("/account-login");
  };

  return (
    <LayoutDefault className="create-number-verify">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={backButtonHandler}
          title={
            <>
              Verify your <br />
              number
            </>
          }
          subTitle={
            <>
              Enter the code we’ve sent by [SMS/WhatsApp] to{" "}
              <span className="phone-text">{formattedNumber || ""}</span>
              <span
                className="anchor-link"
                onClick={() => setComponent("/account-login")}
              >
                Change
              </span>
            </>
          }
        />

        <CodeVerification
          fieldsNumber={5}
          valuesCodesCallback={(e) => setCodeValue(e)}
          isWarning={isWarning}
        />
        <SubText icon={ClockIcon} altIcon="clock icon">
          This code should arrive within 30 seconds. Didn’t receive the code?{" "}
          <span className="resend-code" onClick={() => resendCode()}>
            Resend new code
          </span>
        </SubText>
      </div>

      <ButtonContainer>
        <Button
          disabled={isLoading || hasError || codeValue.length < 5}
          buttonStyle="primary"
          onClick={addPhoneNumberSubmit}
        >
          {isLoading ? (
            <ClipLoader color={"#FFF"} loading={true} size={15} />
          ) : (
            "Continue"
          )}
        </Button>
      </ButtonContainer>
      <Toast
        isVisible={hasError}
        title="Error"
        description={toastMessage}
        type="error"
        setIsVisible={setHasError}
      />
    </LayoutDefault>
  );
};

export default CreateNumberVerifyPage;
