import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import ListItem from "../../components/Lists/ListItem";
import PhotoGallery from "../../components/PhotoGallery";
import { useEffect, useState } from "react";
import ArrowIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useAuth } from "../../hooks/auth";
import MembershipUserInfoApi from "../../api/getMembershipApplication";
import { isEmpty } from "lodash";
import patchMembershipInfo from "../../api/patchMembershipInfo";
import Toast from "../../components/Toast";
import { ClipLoader } from "react-spinners";
import MembershipUserInfoByPhoneApi from "../../api/getMembershipApplicationByPhone";
import { useUserInfo } from "../../hooks/userInfo";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";

const UserInformation: React.FC = () => {
  const [editInfo, setEditInfo] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [instagramProfile, setInstagramProfile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [firstInfo, setFirstInfo] = useState({} as any);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState([] as any[]);
  const [errorMessageToast, setErrorMessageToast] = useState("");
  const [successMessageToast, setSuccessMessageToast] = useState("");
  const [hasSuccess, setHasSuccess] = useState(false);
  const { preRegisterInfo } = useAuth();
  const { setUserInfo, userInfo } = useUserInfo();
  const { setComponent } = useLoginSignUpNavigation();

  useEffect(() => {
    if (isEmpty(userInfo)) {
      const userID = userInfo.user_id;

      if (!userID) {
        setComponent("/account-login");
        return;
      }

      MembershipUserInfoApi(userID).then((data) => {
        const { displayName, instagramUser, phone, type } = data;
        setUserInfo("instagram", instagramUser);
        setUserInfo(
          "account_type",
          type === "CREATOR" ? "creator" : "consumer"
        );
        setUserInfo("user_name", displayName);
        setInstagramProfile(instagramUser);
        setPhoneNumber(`+${phone}`);
        setAccountType(type === "CREATOR" ? "Creator" : "Consumer");
        setDisplayName(displayName);

        MembershipUserInfoByPhoneApi(phone).then((data) => {
          const { photos } = data;
          if (!photos) return;
          setPhotos(photos);
        });
      });
    } else {
      const { user_name, account_type, instagram, phone, user_id } = userInfo;

      setInstagramProfile(instagram);
      setPhoneNumber(phone);
      setAccountType(account_type === "creator" ? "Creator" : "Consumer");
      setDisplayName(user_name);

      if (photos) {
      } else {
        const getPhotos = async () => {
          await MembershipUserInfoByPhoneApi(phoneNumber)
            .then((data) => {
              const { photos } = data;

              setPhotos(photos);
            })
            .catch((error) => setPhotos([]));
        };

        getPhotos();
      }
    }
  }, [preRegisterInfo, phoneNumber, setComponent]);

  const confirmEditionSubmit = async () => {
    let userID = userInfo.user_id;
    setIsLoading(true);

    if (!userID) {
      setComponent("/account-login");
      return;
    }

    const data = {
      displayName,
      instagramUser: instagramProfile,
    };

    const patchInfoResponse = await patchMembershipInfo(userID, data);

    switch (patchInfoResponse?.status) {
      case 200:
        setUserInfo("instagram", instagramProfile);
        setUserInfo("user_name", displayName);
        setHasSuccess(true);
        setSuccessMessageToast(
          "Your information has been updated successfully!"
        );

        setTimeout(() => {
          setHasSuccess(false);
        }, 4000);

        break;

      default:
        setHasError(true);
        setErrorMessageToast(
          "There was a problem with updating your information. Please try again."
        );
        break;
    }

    setEditInfo(false);
    setIsLoading(false);
  };

  const handleGoBack = () => {
    setComponent("/wait-list-application");
  };

  const handleCancel = () => {
    setEditInfo(false);
    setDisplayName(firstInfo.displayName);
    setInstagramProfile(firstInfo.instagramProfile);
  };

  const handleEdit = () => {
    setEditInfo(true);
    setFirstInfo({
      displayName,
      instagramProfile,
    });
  };

  return (
    <LayoutDefault className="user-information">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => handleGoBack()}
          title="My information"
          subTitle={`We continually review our waitlist and will send you a push 
					notification if there are any changes to your application status.`}
        />
        <div className="list-container">
          <ListItem
            showLabel
            showValue={!editInfo}
            labelContent="Display name"
            valueContent={displayName}
            showSeparator
            showInput={editInfo}
            valueInput={displayName}
            onChangeInput={setDisplayName}
            iconRightContent={ArrowIcon}
            className="my-information-list"
          />
          <ListItem
            showLabel
            showValue
            labelContent="Phone number"
            valueContent={phoneNumber}
            showSeparator
            className="my-information-list"
          />
          <ListItem
            showLabel
            showValue
            labelContent="Account type"
            valueContent={accountType}
            showSeparator
            className="my-information-list"
          />
          <ListItem
            showLabel
            showValue={!editInfo}
            labelContent="Instagram"
            valueContent={instagramProfile}
            showSeparator
            showInput={editInfo}
            valueInput={instagramProfile}
            onChangeInput={setInstagramProfile}
            className="my-information-list"
          />
        </div>

        <div className="photo-container">
          <PhotoGallery files={photos} />
        </div>
      </div>

      <ButtonContainer>
        {editInfo ? (
          <>
            <Button
              buttonStyle="primary"
              onClick={confirmEditionSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <ClipLoader color={"#fff"} loading={true} size={15} />
              ) : (
                "Save"
              )}
            </Button>
            <Button buttonStyle="tertiary" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </>
        ) : (
          <Button buttonStyle="tertiary" onClick={() => handleEdit()}>
            Edit
          </Button>
        )}
      </ButtonContainer>

      <Toast
        type="error"
        description={errorMessageToast}
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
      />
      <Toast
        type="success"
        description={successMessageToast}
        isVisible={hasSuccess}
        setIsVisible={setHasSuccess}
        title="Success"
      />
    </LayoutDefault>
  );
};

export default UserInformation;
