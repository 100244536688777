import React, { useEffect, useState } from "react";
import "./styles.scss";
import Rating from "../../Rating";
import Vip from "../../../assets/icons/vip.svg";
import Verify from "../../../assets/icons/verify.svg";
import getUsersListAll from "../../../api/getUsersListAll";
import mock from "../../../mocks/stories";
import { ICatalogResponse } from "../../../types/catalog";

interface CardsCatalogProps {
  catalogHandlerCallback?: (a: any) => void;
  className?: string;
}

const CardsCatalog: React.FC<CardsCatalogProps> = (props) => {
  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);

  const { catalogHandlerCallback, className } = props;

  useEffect(() => {
    const token = sessionStorage.getItem("roses-access-token");
    console.log("token in catalog", token);

    if (!token) return;

    const getCatalogData = async (userToken: string) => {
      let response = await getUsersListAll(userToken);
      console.log(response);
      switch (response?.status) {
        case 200:
          setCatalogData(response.res);
          break;
        default:
          break;
      }
    };

    getCatalogData(token);
  }, []);

  return (
    <div id="catalog-cards" className={className}>
      {catalogData?.map((story, index) => (
        <div
          onClick={() => {
            if (catalogHandlerCallback) {
              catalogHandlerCallback(story);
            }
          }}
          className="catalog-wrapper"
          key={index}
        >
          {/* {story?.isVip && <img src={Vip} alt="vip" className="vip" />} */}
          {true && <img src={Vip} alt="vip icon" className="vip-icon" />}
          <img
            src={story?.profile.photos[0]?.url}
            alt="catalog gallery"
            className="catalog-image"
          />
          <div className="catalog-content">
            <div className="catalog-info">
              <div className="principal-info">
                <p className="catalog-title">{story?.displayname},</p>
                <p className="catalog-title">{story?.profile.age}</p>
                {/* {story?.isVerified && ( */}
                {true && (
                  <img src={Verify} alt="verify icon" className="verify" />
                )}
              </div>

              <Rating rating={story.profile.rating || 4} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardsCatalog;
