import React, { useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import AccountVerification from "../../components/AccountVerifyNotification";
import InputText from "../../components/Inputs/InputText";
import SubText from "../../components/Texts/SubText";
import PhotosPlaceholder from "../../components/PhotosPlaceholder";

import verifyGrayIcon from "../../assets/icons/verify-icon-gray.svg";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import disclaimerIcon from "../../assets/icons/union-gray.svg";
import closePeachIcon from "../../assets/icons/closeIcons/close-icon-peach.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsProfilePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>();

  console.log(activeSection);

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  return (
    <div className="user-settings-profile">
      <PageTitle
        title={"Profile"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
        hasCloseIcon={false}
      />
      <ButtonNavigation
        setActiveSection={handleSectionChange}
        activeSection={activeSection}
        buttonCount={3}
        buttonLabels={["Info", "Photos", "Preference"]}
      />

      {activeSection === "button1" && (
        <>
          <AccountVerification
            labelContent={"Identity Verification"}
            descriptionContent={
              "Let’s make sure nobody is trying to impersonate you."
            }
            showVerifyIcon={true}
            iconContent={verifyGrayIcon}
          />
          <div className="input-container">
            <InputText
              value={"Scarlet"}
              placeholder="Display Name"
              onChangeInput={() => {}}
            />
            <SubText
              children={`This will be shown on your profile. You can change
								 your display name once every 30 days.`}
              icon={disclaimerIcon}
            />
          </div>
        </>
      )}

      {activeSection === "button1" && (
        <>
          <div className="list-container">
            <ListItem
              showLabel={true}
              labelContent="Gender"
              showIconRight={true}
              showValue={true}
              valueContent={"add"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Age"
              showIconRight={true}
              showValue={true}
              valueContent={"add"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Ethinicity"
              showIconRight={true}
              showValue={true}
              valueContent={"Caucasian"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Height"
              showIconRight={true}
              showValue={true}
              valueContent={"5'9/1,82m"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Weight"
              showIconRight={true}
              showValue={true}
              valueContent={"165lb/75kg"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Language"
              showIconRight={true}
              showValue={true}
              valueContent={"English, Spanish..."}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
            />
          </div>
        </>
      )}

      {activeSection === "button2" && (
        <>
          <div className="photo-container">
            <PhotosPlaceholder
              quantityFiles={9}
              photoAlbumCallback={(e) => setPhotosAlbum(e)}
            />
          </div>
          <div className="list-container">
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Preserve my Confidentiality"
              showDescription={true}
              descriptionContent="Conceal my facial identity"
              showToggle={true}
              showSeparator={true}
            />
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Smart Photo"
              showDescription={true}
              descriptionContent="Chooses best photo for you"
              showToggle={true}
            />
          </div>
          <SubText
            children={`We welcome sensual expression that's artistic and 
							sophisticated. Please avoid explicit content to maintain the 
							respectful and tasteful spirit of our community.`}
            icon={disclaimerIcon}
          />
        </>
      )}

      {activeSection === "button3" && (
        <>
          <InputText
            value={"My Current Location"}
            placeholder="Location"
            onChangeInput={() => {}}
          />
          <div className="list-container">
            <ListItem
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Distance"
              showSlider={true}
              showValue={true}
              valueContent={"10km/6.2mi"}
              showSeparator={true}
            />
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Distance Preference"
              showDescription={true}
              descriptionContent="Only show people in this range"
              showToggle={true}
              showSeparator={true}
            />
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Allow Requests"
              showDescription={true}
              descriptionContent="Accept roses requests from everyone"
              showToggle={true}
            />
          </div>
          <div className="list-container">
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Filters"
              showIconRight={true}
              iconRightContent={chevronRightIcon}
              showValue={true}
              valueContent={"add"}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Age"
              showIconRight={true}
              iconRightContent={closePeachIcon}
              showSlider={true}
              showValue={true}
              valueContent={"21-38"}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Ethinicity"
              showIconRight={true}
              iconRightContent={closePeachIcon}
              showValue={true}
              valueContent={"Latina"}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Language"
              showIconRight={true}
              iconRightContent={closePeachIcon}
              showSlider={true}
              showValue={true}
              valueContent={"21-38"}
              showSeparator={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default UserSettingsProfilePage;
