import React, { useState } from "react";
import "./styles.scss";

interface InputRadioProps {
  elements: InputRadioElement[];
  onChangeValueCallback: (a: string) => void;
  selectedValue: string;
}

interface InputRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputRadio: React.FC<InputRadioProps> = (props) => {
  const { elements, onChangeValueCallback, selectedValue } = props;

  return (
    <div id="input-radio-default">
      {elements.map((element, index) => (
        <div className="label-input-container" key={index}>
          <input
            checked={selectedValue === element.inputValue}
            className="input-radio"
            type="radio"
            id={`${element.inputLabel}-${index}`}
            name={element.groupName}
            value={element.inputValue}
            onChange={(e) => onChangeValueCallback(e.target.value)}
          />
          <label htmlFor={`${element.inputLabel}-${index}`}>
            {element.inputLabel}
          </label>
        </div>
      ))}
    </div>
  );
};

export default InputRadio;
