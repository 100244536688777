import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useState } from "react";
import Toast from "../../components/Toast";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";

const AllowNotificationPage: React.FC = () => {
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);

  const { setComponent } = useLoginSignUpNavigation();

  const enableNotification = async () => {
    let permission = await Notification.requestPermission();

    if (permission === "granted") {
      setComponent("/gender-identification");
    } else {
      setError("Permission denied");
      setHasError(true);
    }
  };

  const cancelEnableNotification = () => {
    setComponent("/gender-identification");
  };

  return (
    <LayoutDefault className="allow-notification">
      <Header
        headerIcon="bell"
        backButtonClick={() => setComponent("/allow-notification")}
        title={
          <>
            Allow <br />
            notification
          </>
        }
        subTitle={"We’ll let your know when you get new matches and messages"}
      />

      <ButtonContainer>
        <Button buttonStyle="secondary" onClick={enableNotification}>
          Allow Notifications
        </Button>
        <Button buttonStyle="tertiary" onClick={cancelEnableNotification}>
          Not Now
        </Button>
      </ButtonContainer>
      <Toast
        type="error"
        description={error}
        setIsVisible={setHasError}
        isVisible={hasError}
        title="Error"
      />
    </LayoutDefault>
  );
};

export default AllowNotificationPage;
