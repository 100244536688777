// import { UserDetails } from "../types/interfaces";
import { servicesApi } from "./services-api";

const PatchUserSelfDetailApi = async (userDetails: any, userToken: string) => {
  const apiRoute = `${servicesApi("user")}`;
  const requestBody = JSON.stringify(userDetails);

  try {
    const response = await fetch(apiRoute, {
      method: "PATCH",
      body: requestBody,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });

    const jsonData = await response.json();

    if (!response.ok) {
      throw new Error(
        `Network response was not ok. Status: ${response.status}. Error: ${jsonData.message}`
      );
    }

    return {
      status: response.status,
      data: jsonData,
    };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return {
      status: "error",
    };
  }
};

export default PatchUserSelfDetailApi;
