import { servicesApi } from "./services-api";

export type RegisterMembershipApplication = ReturnType<ReturnType<typeof RegisterMembership>["postRegisterMembership"]>;

const RegisterMembership = () => {
  const apiRoute = `${servicesApi("newMembershipRegister")}`;

  const postRegisterMembership = async (data: {
    displayName: string | undefined;
    phone: string;
    instagramUser: string;
    type: string;
    files: [] | (File | null)[] | undefined;
  }) => {

    const formData = new FormData();

    formData.append("displayName", data.displayName || "");
    formData.append("phone", data.phone);
    formData.append("instagramUser", data.instagramUser);
    formData.append("type", data.type);
    if (data.files) {
      for (const file of data.files) {
        if (file) {
          formData.append("files", file);
        }
      }
    }

    try {
      const response = await fetch(`${apiRoute}`, {
        method: "POST",
        body: formData,
        headers: {
          "accept": "application/json",
        },
      });

      if (!response.ok) {
        // Handle non-successful responses (errors)
        throw new Error(`API call failed with status ${response.status}`);
      }

      const jsonData = await response.json(); // Parse the JSON response

      return {
        status: response.status,
        data: jsonData,
      };
      
    } catch (error) {
      console.error("Error during API call:", error);
      return {
        status: "error",
        //@ts-ignore
        message: error.message,
      };
    }

  }
    return {
      postRegisterMembership
    }
};

export default RegisterMembership();