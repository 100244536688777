import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ProviderProps } from "../types/interfaces";
import { getElementByPath } from "../utils/navigationDesk";

const LoginSignUpContext = createContext(
  {} as {
    setComponent: React.Dispatch<React.SetStateAction<string | ReactNode>>;
    component: ReactNode;
  }
);

const LoginSignUpProvider = ({ children }: ProviderProps) => {
  const [component, setComponent] = useState<string | ReactNode>("");

  useEffect(() => {
    let page;
    if (typeof component === "string") {
      page = getElementByPath(component);
    } else {
      page = component;
    }

    console.log("page : ", page);

    if (page) {
      setComponent(page);
    } else {
      setComponent(null);
    }
  }, [component, setComponent]);

  return (
    <LoginSignUpContext.Provider
      value={{
        component,
        setComponent,
      }}
    >
      {children}
    </LoginSignUpContext.Provider>
  );
};

const useLoginSignUpNavigation = () => {
  const context = useContext(LoginSignUpContext);

  return context;
};

export { LoginSignUpProvider, useLoginSignUpNavigation };
