import React from "react";
import LogInSignUpDesk from "../components/Wrappers/LoginSignUpDesk";
import { useLoginSignUpNavigation } from "../hooks/useLoginSignUpNavigation";
import AccountLoginPage from "./AccountLogin";
import GirlWithRosesLogoEye from "../assets/images/girl-opened-month.png";

const App: React.FC = () => {
  const { component } = useLoginSignUpNavigation();
  return (
    <LogInSignUpDesk
      imageLeft={GirlWithRosesLogoEye}
      component={component || <AccountLoginPage />}
    />
  );
};

export default App;
