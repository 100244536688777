import React from "react";
import "./styles.scss";

import LogoHallowPink from "../../../assets/icons/logos/logo-hollow-pink.svg";
import LogoFillPink from "../../../assets/icons/logos/logo-rose-icon-pink.svg";
import LogoCleanPink from "../../../assets/icons/logos/logo-clean-pink.svg";

interface FooterLPProps {}

const FooterLP: React.FC<FooterLPProps> = (props) => {
  const {} = props;

  return (
    <div id="footer-landing-page">
      <div className="images-links-container">
        <div className="logos-container">
          <img src={LogoHallowPink} alt="roses logo" />
          <img src={LogoFillPink} alt="roses logo" />
          <img src={LogoCleanPink} alt="roses logo" />
        </div>

        {/* <p className="follow-roses">
          Follow <span>@RosesApp</span>
        </p> */}

        {/* <div className="links-contact">
          <a href="/" textkey="6/footerNavigation-01">
            FAQ
          </a>
          <span>|</span>
          <a href="/">Contact Us</a>
          <span>|</span>
          <a href="/">Careers</a>
          <span>|</span>
          <a href="/">Investors</a>
        </div> */}
      </div>
      <div className="separator"></div>
      <div className="links-terms">
        <a href="/terms-conditions" textkey="6/footerNavigation-04">
          Terms & Conditions
        </a>
        <span>|</span>
        <a href="/privacy-policy" textkey="6/footerNavigation-05">
          Privacy Policy
        </a>
        <span>|</span>
        <a href="/usc2257" textkey="6/footerNavigation-06">
          USC 2257
        </a>
        <span>|</span>
        <a href="mailto:support@roseselite.com" textkey="6/footerNavigation-07">
          Customer Support
        </a>

        <p textkey="6/footerCopyright">
          Roses.vip , LLC - Copyright 2024<span>|</span>All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default FooterLP;
