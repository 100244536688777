import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import identityIconGray from "../../assets/icons/identity-icon-gray.svg";
import notificationsIconGray from "../../assets/icons/notification-icon-gray.svg";
import privacyIconGray from "../../assets/icons/privacy-icon-gray.svg";
import personalSafetyIconGray from "../../assets/icons/personal-icon-gray.svg";
import myOffersIconGray from "../../assets/icons/offers-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsAccountPage: React.FC = () => {
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();
  return (
    <div className="user-settings-account">
      <div className="list-container-user-settings">
        <PageTitle
          title={"Account"}
          subtitle={"Manage Account"}
          onBackClick={() => setPrincipalScreen("/user-settings")}
          hasCloseIcon={false}
        />
        <ListItem
          showLabel={true}
          labelContent="Identity Verification"
          showIconLeft={true}
          iconLeftContent={identityIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
        />
        <ListItem
          showLabel={true}
          labelContent="Language"
          showIconLeft={true}
          iconLeftContent={notificationsIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("/user-settings-language")}
        />
        <ListItem
          showLabel={true}
          labelContent="Password & Security"
          showIconLeft={true}
          iconLeftContent={privacyIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() =>
            setPrincipalScreen("/user-settings-change-password")
          }
        />
        <ListItem
          showLabel={true}
          labelContent="Account Privacy"
          showIconLeft={true}
          iconLeftContent={personalSafetyIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("/user-settings-privacy")}
        />
        <ListItem
          showLabel={true}
          labelContent="Manage Account"
          showIconLeft={true}
          iconLeftContent={myOffersIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          clickAction={() =>
            setPrincipalScreen("/user-settings-manage-account")
          }
        />
      </div>
      <ButtonContainer>
        <Button buttonStyle="secondary" onClick={() => {}}>
          Logout
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default UserSettingsAccountPage;
