import React, { ReactNode } from "react";
import "./styles.scss";

interface TitleTextProps {
  children: ReactNode;
  size?: "h1" | "h2" | "h3" | "h4" | "title";
}

const TitleText: React.FC<TitleTextProps> = ({ children, size = "h3" }) => {
  return (
    <p id="title-text" className={size}>
      {children}
    </p>
  );
};

export default TitleText;
