import { servicesApi } from "./services-api";

const MembershipReferralAddApi = (userId: string, referralCode: string) => {
  const apiRoute = `${servicesApi(
    'applications'
  )}/${userId}/referrals/${referralCode}`;

  return fetch(apiRoute, {
    method: 'POST',
  })
    .then(response => {
      if (!response.ok) {
        return response.json().then(errorData => {
          throw new Error(
            `Network response was not ok Status: ${response.status}. Error: ${errorData.message}`
          );
        });
      }
      return response;
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      throw error;
    });
};

export default MembershipReferralAddApi;