import React, { ReactNode } from "react";
import "./styles.scss";

interface CenterTextProps {
  icon?: string;
  altIcon?: string;
  value?: ReactNode;
  valueFontSize?: string;
  title: ReactNode;
  subtitle: ReactNode;
  titleFontSize?: string;
  subtitleFontSize?: string;
  imgHeight?: string;
  imgWidth?: string;
  maxWidth?: string | number;
}

const CenterText: React.FC<CenterTextProps> = (props) => {
  const {
    icon,
    altIcon,
    value,
    valueFontSize,
    title,
    subtitle,
    titleFontSize,
    subtitleFontSize,
    imgHeight,
    imgWidth,
    maxWidth,
  } = props;

  const titleStyle = { fontSize: titleFontSize, maxWidth: maxWidth };
  const subtitleStyle = { fontSize: subtitleFontSize, maxWidth: maxWidth };
  const valueStyle = { fontSize: valueFontSize };
  const imgStyle = { height: imgHeight, width: imgWidth };

  return (
    <div id="icon-and-text">
      {icon && <img src={icon} alt={altIcon} style={imgStyle} />}
      {value && (
        <div className="value-text" style={valueStyle}>
          {value}
        </div>
      )}
      <div className="text-container">
        <h2 className="title-text" style={titleStyle}>
          {title}
        </h2>
        <div className="subtitle-text" style={subtitleStyle}>
          {subtitle}
        </div>
      </div>
    </div>
  );
};

export default CenterText;
