import { servicesApi } from "./services-api";

const ProfileDetails = async (userId: string, token: string) => {
  const apiRoute = `${servicesApi("userDetail")}/${userId}`;
  const authorizationHeader = `Bearer ${token}`;
  try {
    const response = await fetch(apiRoute, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorizationHeader,
      },
    });
    const data = await response.json();

    return { res: data, status: response.status };
  } catch (error) {
    return { res: error };
  }
};

export default ProfileDetails;
