import { servicesApi } from "./services-api";

const getUsersListAll = async (token: string) => {
  const apiRoute = `${servicesApi("users")}`;

  const authorizationHeader = `Bearer ${token}`;

  try {
    const response = await fetch(apiRoute, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorizationHeader,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return { res: data, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export default getUsersListAll;
