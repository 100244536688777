import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import InfoIcon from "../../assets/icons/union-gray.svg";
import Button from "../../components/Buttons/Button";
import ListItem from "../../components/Lists/ListItem";
import SubText from "../../components/Texts/SubText";
import RightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useAuth } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MembershipUserInfoApi from "../../api/getMembershipApplication";
import MembershipReferralListApi from "../../api/getMembershipReferral";
import { isEmpty } from "lodash";
import MembershipUpdateStatusAdminApi from "../../api/patchMembershipApplicationAdminStatus";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";

const WaitListApplicationPage: React.FC = () => {
  const [displayName, setDisplayName] = useState("");
  const [referrals, setReferrals] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [, setIsLoading] = useState(false);
  const { preRegisterInfo, setPreRegisterInfo } = useAuth();
  const { phone } = preRegisterInfo;
  const phoneToUse = phone || phoneNumber;
  const navigate = useNavigate();
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  const approveRegisterSubmit = async () => {
    setIsLoading(true);
    const approveResponse = await MembershipUpdateStatusAdminApi(phoneToUse);

    switch (approveResponse?.status) {
      case 200:
        setUserInfo("user_id", approveResponse?.res.id);
        setUserInfo("user_name", approveResponse?.res.displayName);
        setUserInfo("phone", approveResponse?.res.phone);
        setUserInfo("instagram", approveResponse?.res.instagramUser);
        setUserInfo("account_type", approveResponse?.res.type);

        if (approveResponse.res.status === "APPROVED") {
          setComponent("/welcome-user");
          localStorage.clear();
        } else {
          console.error(
            "Unexpected response from update operation",
            approveResponse
          );
        }
        break;
      default:
        console.error(
          "Unexpected response from update operation",
          approveResponse
        );
        break;
    }
  };

  const renderApproveButton = () => {
    if (
      phoneToUse === "5511967700452" ||
      phoneToUse === "14074464664" ||
      phoneToUse === "13053704780" ||
      phoneToUse === "5545999640276" ||
      phoneToUse === "5511914013654" ||
      phoneToUse === "5519984484218"
    ) {
      return (
        <>
          <Button buttonStyle="primary" onClick={approveRegisterSubmit}>
            Approve
          </Button>
        </>
      );
    }
  };

  const logoutHandler = async () => {
    navigate("/");
    localStorage.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    if (isEmpty(preRegisterInfo)) {
      const userID = userInfo.user_id;

      if (!userID) {
        setComponent("/account-login");
        return;
      }

      MembershipUserInfoApi(userID).then((data) => {
        setDisplayName(data.displayName);
        setPhoneNumber(data.phone);
        setPreRegisterInfo(data);
      });

      MembershipReferralListApi(userID).then((data) => {
        setReferrals(data.records);
      });
    } else {
      const { displayName, id } = preRegisterInfo;

      if (!displayName || !id) return;

      setDisplayName(displayName);

      MembershipReferralListApi(id).then((data) => {
        setReferrals(data.records);
      });
    }
  }, [preRegisterInfo, setComponent, setPreRegisterInfo, setComponent]);

  return (
    <LayoutDefault className="wait-list-application">
      <div>
        <Header
          headerIcon="roses-logo"
          backButtonClick={() => {}}
          title={
            <>
              Thank you,
              <br />
              Your application is <br />
              currently on our waitlist.
            </>
          }
          subTitle={`We continually review our waitlist and will send you a push
					 notification if there are any changes to your application status.`}
        />
        <div className="list-container">
          <ListItem
            showLabel
            showValue
            showIconRight
            labelContent="My Information"
            valueContent={displayName}
            iconRightContent={RightIcon}
            showSeparator
            clickAction={() => setComponent("/user-information")}
            className="information-item"
          />
          <ListItem
            showLabel
            showValue
            showIconRight
            labelContent="Add referrals"
            valueContent={`${referrals} referrals`}
            iconRightContent={RightIcon}
            clickAction={() => setComponent("/add-referrals")}
            className="information-item"
          />
        </div>
      </div>

      <SubText
        icon={InfoIcon}
        altIcon="info icon"
        className="subtext-info"
        fontSize="12px"
      >
        If you would like to list members to support your application, you can
        do so by adding referrals or by inviting friends above.
      </SubText>

      <ButtonContainer>
        {renderApproveButton()}
        <Button buttonStyle="quaternary" onClick={logoutHandler}>
          Logout
        </Button>
      </ButtonContainer>
    </LayoutDefault>
  );
};

export default WaitListApplicationPage;
