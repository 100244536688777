import React, { createContext, useState, useContext, useEffect } from "react";
import {
  AuthContextData,
  IPreRegister,
  ProviderProps,
} from "../types/interfaces";
import postUserAuth from "../api/postUserAuth";
import * as jwtDecode from "jwt-decode";

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
  const [user, setUser] = useState({} as any);
  const [preRegisterInfo, setPreRegisterInfo] = useState({} as IPreRegister);
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    if (!preRegisterInfo.userID) {
      const userID = localStorage.getItem("roses-userid");

      if (!userID) {
        return;
      }

      const getToken = getAccessToken();

      if (getToken) {
        setAccessToken(getToken);
      }
      setPreRegisterInfo({ userID });
      setUser({ userID });
    }
  }, [preRegisterInfo]);

  const login = async (input: { phone: string; password: string }) => {
    const auth = await postUserAuth.postUserAuth(input).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    });

    const token = (await jwtDecode.jwtDecode(auth.access_token)) as any;
    localStorage.setItem("roses-userid", token.validUser.userId);
    setUser(token.validUser);
    window.location.href = "/";
  };

  const getAccessToken = () => localStorage.getItem("roses-access-token");

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        preRegisterInfo,
        setPreRegisterInfo,
        login,
        getAccessToken,
        accessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);

  return context;
};

export { AuthProvider, useAuth };
