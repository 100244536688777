import { useCallback, useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import ListItem from "../../components/Lists/ListItem";
import CheckIconGreen from "../../assets/icons/check-simple-green.svg";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useAuth } from "../../hooks/auth";
import MembershipUserInfoApi from "../../api/getMembershipApplication";
import MembershipReferralListApi from "../../api/getMembershipReferral";
import MembershipReferralAddApi from "../../api/postMembershipReferral";
import Toast from "../../components/Toast";
import { useUserInfo } from "../../hooks/userInfo";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";

const AddReferralsPage: React.FC = () => {
  const [referral, setReferral] = useState("");
  const [myReferralCode, setReferralCode] = useState("");
  const [referralList, setReferralList] = useState([] as any[]);
  const [userIdentity, setUserIdentity] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [toastSuccessMessage, setToastSuccessMessage] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const { setComponent } = useLoginSignUpNavigation();
  const { preRegisterInfo } = useAuth();
  const { userInfo, setUserInfo } = useUserInfo();

  useEffect(() => {
    if (preRegisterInfo) {
      const userID = userInfo.user_id;

      if (!userID) {
        setComponent("/account-login");
        return;
      } else {
        setUserIdentity(userID);
      }

      const fetchReferralCode = async () => {
        await MembershipUserInfoApi(userID).then((data) => {
          setReferralCode(data.referralCode);
        });
      };

      fetchReferralCode();
      fetchReferralList(userID);
    }
  }, [preRegisterInfo, setComponent]);

  const useCopyCode = () =>
    useCallback(() => {
      navigator.clipboard.writeText(myReferralCode);
      setCopySuccess(true);
      setSuccess(true);
      setToastSuccessMessage("Code copied to clipboard.");

      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    }, [myReferralCode]);

  const copyCode = useCopyCode();

  const fetchReferralList = async (idToSearch: any) => {
    await MembershipReferralListApi(idToSearch)
      .then((response) => {
        if (response && response.data) {
          setReferralList(response.data);
        } else {
          console.error(
            "Error: No referral data available in response:",
            response
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching referral list:", error);
      });
  };

  const useAddReferral = () =>
    useCallback(() => {
      if (referral.trim() === "") {
        return;
      }

      MembershipReferralAddApi(userIdentity, referral)
        .then((response) => {
          if (response.status === 201) {
            fetchReferralList(userIdentity);
            setReferral("");
            setSuccess(true);
            setToastSuccessMessage("Referral added successfully.");

            setTimeout(() => {
              setSuccess(false);
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Error adding referral:", error);
          setError(true);
        });
    }, [referral]);

  const addReferral = useAddReferral();

  const handleInput = (e: string) => {
    if (e.length > 6) {
      return;
    }
    setReferral(e.toUpperCase());
  };

  return (
    <LayoutDefault className="add-referrals">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => setComponent("/wait-list-application")}
          title="Add referrals"
          subTitle={
            <>
              Enter a referral code from a shared link or invite friends to join
              Roses using your referral code:{" "}
              <span className="code-text">{myReferralCode}</span>{" "}
            </>
          }
        />
        <InputText
          value={referral}
          placeholder="Enter referral code"
          onChangeInput={(e) => handleInput(e)}
        />

        {referralList?.map((referral, index) => (
          <ListItem
            key={index}
            showLabel={true}
            labelContent={referral.displayName}
            showValue
            valueContent={referral.referralCode}
            showIconLeft
            iconLeftContent={CheckIconGreen}
            showSeparator={referralList.length > index + 1}
          />
        ))}
      </div>

      <ButtonContainer>
        <Button buttonStyle="primary" onClick={addReferral}>
          Add Referral
        </Button>
        <Button buttonStyle="tertiary" onClick={copyCode}>
          Copy Code
        </Button>
      </ButtonContainer>

      <Toast
        type="success"
        isVisible={success}
        setIsVisible={setSuccess}
        title="Success"
        description={toastSuccessMessage}
      />
      <Toast
        type="error"
        isVisible={error}
        setIsVisible={setError}
        title="Error"
        description="Error adding referral."
      />
    </LayoutDefault>
  );
};

export default AddReferralsPage;
