import { useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import GalleryIcon from "../../assets/icons/keyBoardIcons/gallery-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import PhotosPlaceholder from "../../components/PhotosPlaceholder";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import postNewMembership from "../../api/postNewMembership";
import Toast from "../../components/Toast";
import { ClipLoader } from "react-spinners";
import { useUserInfo } from "../../hooks/userInfo";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";

const SendPhotoAlbumPage: React.FC = () => {
  const [instagramProfile, setInstagramProfile] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>();
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  const isCreator = userInfo.account_type === "creator";

  const sendPhotoSubmit = async () => {
    setLoading(true);

    const prepareData = {
      displayName: userInfo.user_name,
      phone: userInfo.phone.split("+")[1] as string,
      instagramUser: instagramProfile,
      type: userInfo.account_type === "creator" ? "CREATOR" : "CONSUMER",
      files: photosAlbum,
    };

    const response = await postNewMembership.postRegisterMembership(
      prepareData
    );

    switch (response.status) {
      case 201:
        setUserInfo("user_id", response.data.id);
        setComponent("/wait-list-application");
        break;

      default:
        setHasError(true);
        setToastErrorMessage("There was an error. Please try again.");

        setTimeout(() => {
          setHasError(false);
        }, 5000);

        break;
    }

    setLoading(false);
  };

  const handleGoBack = () => {
    setComponent("/create-name");
  };

  const renderHeaderText = () => {
    if (isCreator) {
      return {
        title: (
          <>
            Add your <br />
            photos
          </>
        ),
        subTitle: "Select 3 photos that clearly shows you.",
        subInputText: `We welcome sensual expression that's artistic and
				sophisticated. Please avoid explicit content to maintain the respectful and
				 tasteful spirit of our community.`,
      };
    } else {
      return {
        title: (
          <>
            Add your <br />
            Instagram
          </>
        ),
        subTitle: "Add your Instagram profile.",
        subInputText: `Boost your approval chances by adding your Instagram 
				profile. Your profile must be set to public. (highly recommended).`,
      };
    }
  };

  const onChangeInput = (instagram: string) => {
    let userInstagram;
    if (instagram[0] !== "@") {
      userInstagram = "@" + instagram;
    } else {
      userInstagram = instagram;
    }

    setInstagramProfile(userInstagram);
  };

  return (
    <LayoutDefault className="send-photo-album">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={handleGoBack}
          title={renderHeaderText().title}
          subTitle={renderHeaderText().subTitle}
        />
        {isCreator ? (
          <PhotosPlaceholder
            quantityFiles={3}
            photoAlbumCallback={(e) => setPhotosAlbum(e)}
          />
        ) : null}
        {isCreator ? (
          <SubText>
            Boost your approval chances by adding your Instagram profile. Your
            profile must be set to public (highly recommended).
          </SubText>
        ) : null}
        <InputText
          value={instagramProfile}
          placeholder="@instagram (optional)"
          onChangeInput={(e) => onChangeInput(e)}
          textTransform="none"
        />

        <SubText
          icon={isCreator ? GalleryIcon : ""}
          altIcon="gallery photos icon"
        >
          {renderHeaderText().subInputText}
        </SubText>
      </div>
      <ButtonContainer>
        <Button
          buttonStyle="primary"
          onClick={sendPhotoSubmit}
          disabled={loading}
        >
          {loading ? (
            <ClipLoader color={"#fff"} loading={true} size={15} />
          ) : (
            "Submit application"
          )}
        </Button>
      </ButtonContainer>
      <Toast
        type="error"
        title="Error"
        description={toastErrorMessage}
        isVisible={hasError}
        setIsVisible={setHasError}
      />
    </LayoutDefault>
  );
};

export default SendPhotoAlbumPage;
