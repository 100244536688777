import React, { ReactNode } from "react";
import "./styles.scss";

import ChevronClose from "../../assets/icons/closeIcons/close-icon-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ChevronLeft from "../../assets/icons/navigationIcons/arrow-left-gray.svg";

interface PageTitleProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  onBackClick?: () => void;
  isChat?: boolean;
  avatarUrl?: string;
  isOnLine?: boolean;
  mediaButtonClick?: (a: any) => void;
  hasCloseIcon?: boolean;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subtitle,
  onBackClick,
  isChat,
  avatarUrl,
  isOnLine,
  mediaButtonClick,
  hasCloseIcon = true,
}) => {
  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  return (
    <div className="page-title-container">
      <div className="back-button" onClick={handleBackClick}>
        <img
          src={hasCloseIcon ? ChevronClose : ChevronLeft}
          alt="x back icon"
        />
      </div>
      {isChat && (
        <div className="chat-icon">
          <img
            className="chat-icon-img"
            src={avatarUrl || "https://picsum.photos/430/700"}
            alt="chat icon"
          />
        </div>
      )}
      <div className="text-wrapper">
        <div className="title">{title}</div>
        {subtitle && (
          <div className="subtitle-wrapper">
            {isChat && (
              <div
                className={`chat-icon-stats  ${
                  isOnLine ? "interest-online" : "interest-offline"
                }`}
              />
            )}
            <div className="subtitle">{subtitle}</div>
          </div>
        )}
      </div>
      {isChat && (
        <div className="open-media">
          <button className="open-media-button" onClick={mediaButtonClick}>
            MEDIA
          </button>
        </div>
      )}
    </div>
  );
};

export default PageTitle;
