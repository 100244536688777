import React, { useEffect, useState } from "react";
import "./styles.scss";
import Header from "../../components/Headers/Header";
import CodeAndPhoneNumber from "../../components/CodeAndPhoneNumber";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import InputRadio from "../../components/Inputs/InputRadio";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import Toast from "../../components/Toast";
import VerifyPhoneApiWpp from "../../api/postVerifyPhoneWPP";
import VerifyPhoneApiSms from "../../api/postVerifyPhoneSMS";
import { ClipLoader } from "react-spinners";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import { useUserInfo } from "../../hooks/userInfo";

const AccountLoginPage: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendingMethod, setSendingMethod] = useState("whatsapp");
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isLoadingApply, setIsLoadingApply] = useState(false);
  const [isLoadingApplication, setIsLoadingApplication] = useState(false);
  const [hasCountryCode, setHasCountryCode] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [countryCode, setCountryCode] = useState("" as string);
  const { setComponent } = useLoginSignUpNavigation();
  const { setUserInfo } = useUserInfo();

  useEffect(() => {
    if (countryCode) {
      setHasCountryCode(true);
    }
  }, [countryCode]);

  const verifyWpp = async (phone: string) => {
    return await VerifyPhoneApiWpp.createVerification(phone).requestCode();
  };

  const verifySMS = async (phone: string) => {
    return await VerifyPhoneApiSms.createVerification(phone).requestCode();
  };

  const sendCodeSubmit = async (button: "apply" | "application" | "login") => {
    if (button === "login") setIsLoadingLogin(true);
    if (button === "application") setIsLoadingApplication(true);
    if (button === "apply") setIsLoadingApply(true);

    if (phoneNumber === "" || !hasCountryCode) {
      setHasError(true);
    } else {
      setUserInfo("phone", `${countryCode}${phoneNumber}`);
      const removePlus = countryCode.split("+")[1] + phoneNumber;

      const response =
        sendingMethod === "whatsapp"
          ? await verifyWpp(removePlus)
          : await verifySMS(removePlus);

      switch (response.status) {
        case 200:
          localStorage.setItem("@from", "login");
          setComponent("/create-number-verify");
          break;

        default:
          setHasError(true);
          setTimeout(() => {
            setHasError(false);
          }, 3000);
          break;
      }
    }
    setIsLoadingLogin(false);
    setIsLoadingApply(false);
    setIsLoadingApplication(false);
  };

  return (
    <LayoutDefault className="account-login-page">
      <div>
        <Header
          title="Welcome!"
          headerIcon="roses-logo"
          subTitle="For your security, please log in to continue accessing our platform."
        />
        <CodeAndPhoneNumber
          setCountryCode={(e) => setCountryCode(e)}
          inputValueCallback={(e) => setPhoneNumber(e)}
          isInputWarning={phoneNumber === "" && hasError}
          isSelectWarning={!hasCountryCode}
        />
        <InputRadio
          onChangeValueCallback={(value) => setSendingMethod(value)}
          selectedValue={sendingMethod}
          elements={[
            {
              groupName: "send-code",
              inputLabel: "whatsApp",
              inputValue: "whatsapp",
            },
            {
              groupName: "send-code",
              inputLabel: "SMS",
              inputValue: "sms",
            },
          ]}
        />
        <SubText icon={LockIcon}>
          We never share this with anyone and it won’t be on your profile.
        </SubText>
      </div>

      <ButtonContainer>
        <Button
          disabled={
            hasError || isLoadingLogin || isLoadingApplication || isLoadingApply
          }
          buttonStyle="primary"
          onClick={() => sendCodeSubmit("apply")}
        >
          {isLoadingApply ? (
            <ClipLoader color={"#fff"} loading={true} size={15} />
          ) : (
            "Apply for Membership"
          )}
        </Button>
        <Button
          disabled={
            hasError || isLoadingLogin || isLoadingApplication || isLoadingApply
          }
          buttonStyle="secondary"
          onClick={() => sendCodeSubmit("application")}
        >
          {isLoadingApplication ? (
            <ClipLoader color={"#fff"} loading={true} size={15} />
          ) : (
            "Check Application Status"
          )}
        </Button>
        <Button
          disabled={
            hasError || isLoadingLogin || isLoadingApplication || isLoadingApply
          }
          buttonStyle="quaternary"
          onClick={() => sendCodeSubmit("login")}
        >
          {isLoadingLogin ? (
            <ClipLoader color={"#757575"} loading={true} size={15} />
          ) : (
            "Login"
          )}
        </Button>
      </ButtonContainer>
      <Toast
        type="error"
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
        description="Please fill all the fields correctly."
      />
    </LayoutDefault>
  );
};

export default AccountLoginPage;
