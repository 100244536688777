import { useState } from "react";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import Button from "../../components/Buttons/Button";
import RadioList from "../../components/Lists/RadioList";

import hideGrayIcon from "../../assets/icons/hide-icon-gray.svg";
import closePeachIcon from "../../assets/icons/closeIcons/close-icon-peach.svg";
import LogoEmpty from "../../assets/icons/logos/logo-empty-gray.svg";
import LogoHallow from "../../assets/icons/logos/logo-hollow-gray.svg";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";

const UserSettingsSubscriptionPage: React.FC = () => {
  const [chosenType, setChosenType] = useState("");

  const elementsListChoose = [
    {
      inputIcon: LogoEmpty,
      inputIconAlt: "logo roses gray",
      inputTitle: "Creator",
      inputText: "Your Talent, Your Platform: Create, Share, Thrive.",
      inputValue: "creator",
      groupName: "accountType",
      sphereIcon: "pink",
      checked: chosenType === "creator",
    },
    {
      inputIcon: LogoHallow,
      inputIconAlt: "logo roses gray",
      inputTitle: "Consumer",
      inputText: "Explore Your Passions: Engage, Support, Discover.",
      inputValue: "consumer",
      groupName: "accountType",
      sphereIcon: "blue",
      checked: chosenType === "consumer",
    },
  ];
  console.log(chosenType);
  return (
    <LayoutDefault className="user-settings">
      <div>
        <PageTitle title={"Subscription"} subtitle={"Manage Plans"} />
        <RadioList
          elements={elementsListChoose}
          onChangeValueCallback={(e) => setChosenType(e)}
        />
      </div>

      <ButtonContainer>
        <Button buttonStyle="primary" onClick={() => {}}>
          Subscribe
        </Button>
      </ButtonContainer>
    </LayoutDefault>
  );
};

export default UserSettingsSubscriptionPage;
