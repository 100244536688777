import { useState } from "react";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import ShieldIconGray from "../../assets/icons/personal-icon-gray.svg";
import hideGrayIcon from "../../assets/icons/hide-icon-gray.svg";
import blockGrayIcon from "../../assets/icons/privacy-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import SubText from "../../components/Texts/SubText";
import CloseIconPeach from "../../assets/icons/closeIcons/close-icon-peach.svg";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";

const UserSettingsPrivacyPage: React.FC = () => {
  const [isProfileHidden, setIsProfileHidden] = useState(false);
  const [isBlockRequests, setIsBlockRequests] = useState(false);

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const locationMock = [
    {
      country: "Italy",
      city: "Florence",
    },
    {
      country: "Italy",
      city: "Rome",
    },
  ];

  const handleToggleProfileHidden = (newState: boolean) => {
    console.log("is profile hidden?", isProfileHidden);
    setIsProfileHidden(newState);
  };

  const handleToggleBlockRequests = (newState: boolean) => {
    setIsBlockRequests(newState);
  };

  const activateLocationShieldHandler = () => {};
  const hideSpecificLocationHandler = () => {};
  const addLocationHandler = () => {};

  return (
    <div className="user-settings-privacy">
      <div className="list-container">
        <PageTitle
          title={"Privacy"}
          subtitle={"Configuration"}
          hasCloseIcon={false}
          onBackClick={() => setPrincipalScreen("/user-settings-account")}
        />

        <SubText>
          Emergency Assistance lets you choose contacts for emergencies.
          Contacts are notified only if an emergency occurs, receiving your last
          location and activity details for prompt help.
        </SubText>

        <ListItem
          showLabel={true}
          labelContent="Activate Location Shield"
          showDescription={true}
          descriptionContent="Profile will only show to specific users"
          showIconLeft={true}
          iconLeftContent={ShieldIconGray}
          showIconBorderLeft={true}
          showToggle
          onToggleChange={activateLocationShieldHandler}
          showSeparator={true}
        />
        <ListItem
          showLabel={true}
          labelContent="Hide specific location"
          showDescription={true}
          descriptionContent="Your profile will be hidden from users in these locations"
          showIconBorderLeft={true}
          showToggle
          onToggleChange={hideSpecificLocationHandler}
          showSeparator={true}
        />
        <div className="location-list-item">
          {locationMock.map((location, index) => (
            <ListItem
              key={index}
              showLabel={true}
              labelContent={location.city + ", " + location.country}
              showIconRight
              iconRightContent={CloseIconPeach}
              showSeparator={locationMock.length > index + 1}
            />
          ))}
        </div>

        <ButtonContainer>
          <Button buttonStyle="tertiary" onClick={addLocationHandler}>
            Add location
          </Button>
        </ButtonContainer>
        {/* 
        <ListItem
          showLabel={true}
          labelContent="Hide Profile"
          showDescription={true}
          descriptionContent="Profile will not show to others"
          showIconLeft={true}
          iconLeftContent={hideGrayIcon}
          showIconBorderLeft={true}
          showToggle
          onToggleChange={handleToggleProfileHidden}
          showSeparator={true}
        />
        {isProfileHidden && (
          <div className="is-profile-hidden">
            <ListItem
              showLabel={true}
              labelContent="Block Everyone"
              showDescription={true}
              descriptionContent="Profile will not show to others"
              showRadioRight
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Block Location"
              showDescription={true}
              descriptionContent="Profile will not show to others"
              showRadioRight
            />
            <Button
              children={"Add location"}
              buttonStyle="tertiary"
              onClick={() => {}}
            />
          </div>
        )} */}
        {/* <ListItem
          showLabel={true}
          labelContent="Block Requests"
          showDescription={true}
          descriptionContent="Prohibit rose requests"
          showIconLeft={true}
          iconLeftContent={blockGrayIcon}
          showIconBorderLeft={true}
          showToggle
          onToggleChange={handleToggleBlockRequests}
        /> */}
      </div>
    </div>
  );
};

export default UserSettingsPrivacyPage;
