import React, { useEffect } from "react";
import "./styles.scss";
import "../../../scss/icons.scss";

import heartIconPink from "../../../assets/icons/heart-icon-solid-pink.svg";
import logoIconPink from "../../../assets/icons/logos/logo-rose-icon-pink.svg";
import Button from "../../Buttons/Button";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../contexts/languageContext";
import { useDeskNavigation } from "../../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../../hooks/useLoginSignUpNavigation";
import AccountLoginPage from "../../../pages/AccountLogin";

const useToggleAnimation = (
  selector: string,
  animationClass: string,
  duration: number,
  interval: number
) => {
  useEffect(() => {
    const elements = document.querySelectorAll(selector);

    const toggleAnimation = () => {
      elements.forEach((element) => {
        element.classList.add(animationClass);
        setTimeout(() => {
          element.classList.remove(animationClass);
        }, duration);
      });
    };

    toggleAnimation(); // Initial call
    const intervalId = setInterval(toggleAnimation, interval);

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
    };
  }, [selector, animationClass, duration, interval]);
};

const HeaderLandingPage: React.FC = () => {
  useToggleAnimation(".logo-wrapper", "animateClover", 1500, 80000);
  useToggleAnimation(".loading-wrapper", "animateClover", 500, 2500);
  const navigate = useNavigate();
  const { language, setLanguage } = useLanguage();

  const { setComponent, component } = useLoginSignUpNavigation();

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  useEffect(() => {
    const handleScroll = () => {
      const headerWrapper = document.querySelector(".header-wrapper");
      if (headerWrapper) {
        if (window.scrollY > 80) {
          headerWrapper.classList.add("scroll");
        } else {
          headerWrapper.classList.remove("scroll");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTriggerClick = () => {
    const navWrapper = document.querySelector(".nav-wrapper");
    if (navWrapper) {
      navWrapper.classList.toggle("open");
    }
  };

  const handleLogin = () => {
    console.log("cliquei no login");
    setComponent(<AccountLoginPage />);
    navigate("/home");
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value as any);
  };

  return (
    <section id="header" className="header-wrapper wrapper">
      <div className="header-wrapper-inner inner">
        <div id="logo" className="logo-wrapper">
          <a href="/">
            <div className="logo">
              <span className="icon icon-heart-solid"></span>
              <span className="icon icon-heart-solid"></span>
              <span className="icon icon-heart-solid"></span>
              <span className="icon icon-heart-solid"></span>
              <span className="icon icon-roses-default"></span>
            </div>
            <span className="logo-type">
              Roses<sup>™</sup>
            </span>
          </a>
        </div>

        <div className="nav-wrapper">
          <ul className="nav">
            <li>
              <a href="#intro" textkey="1/navigation-01">
                Why Us
              </a>
            </li>
            <li>
              <a href="#features" textkey="1/navigation-02">
                Features
              </a>
            </li>
            {/* <li>
              <a href="#plans">Plans</a>
            </li> */}

            <select id="language-code" value={language} onChange={handleChange}>
              <option value="ar">🇸🇦&nbsp;&nbsp;العربية</option>
              <option value="de">🇩🇪&nbsp;&nbsp;Deutsch</option>
              <option value="en">🇬🇧&nbsp;&nbsp;English</option>
              <option value="es-ES">🇪🇸&nbsp;&nbsp;Español</option>
              <option value="fr">🇫🇷&nbsp;&nbsp;Français</option>
              <option value="hi">🇮🇳&nbsp;&nbsp;हिन्दी</option>
              <option value="it">🇮🇹&nbsp;&nbsp;Italiano</option>
              <option value="ja">🇯🇵&nbsp;&nbsp;日本語</option>
              <option value="ko">🇰🇷&nbsp;&nbsp;한국어</option>
              <option value="pt-BR">🇧🇷&nbsp;&nbsp;Português (Br)</option>
              <option value="pt-PT">🇵🇹&nbsp;&nbsp;Português (Pt)</option>
              <option value="ro">🇷🇴&nbsp;&nbsp;Română</option>
              <option value="ru">🇷🇺&nbsp;&nbsp;Русский</option>
              <option value="uk">🇺🇦&nbsp;&nbsp;Українська</option>
              <option value="zh-CN">🇨🇳&nbsp;&nbsp;中文 (简体)</option>
              <option value="zh-TW">🇹🇼&nbsp;&nbsp;中文 (繁體)</option>
            </select>
            {/* <li className="header-button-container">
              <Button
                children={"Login"}
                buttonStyle="primary"
                onClick={handleLogin}
              />
            </li> */}
          </ul>
          <div className="trigger" onClick={handleTriggerClick}></div>
        </div>
      </div>
    </section>
  );
};

export default HeaderLandingPage;
