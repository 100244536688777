import { servicesApi } from "./services-api";

const UserInfoByPhoneApi = async (phone: string) => {
  const apiRoute = `${servicesApi("userInfoPhone")}/by-phone/${phone}`;
  const response = await fetch(apiRoute, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

export default UserInfoByPhoneApi;
