import React, { ReactNode } from "react";
import "./styles.scss";

import Button from "../../Buttons/Button";
import infoGrayIcon from "../../../assets/icons/union-gray.svg";

interface RosesDashboardProps {
  showAddButton?: boolean;
  showRedeemButton?: boolean;
  title: ReactNode;
}

const RosesDashboard: React.FC<RosesDashboardProps> = ({
  showAddButton = false,
  showRedeemButton = false,
  title = "0",
}) => {
  return (
    <div className="roses-dashboard-container">
      <div className="text-container">
        <div className="title-text">{title}</div>
        <div className="subtitle-text">
          Available Roses
          <img src={infoGrayIcon} alt="Disclaimer Icon" className="icon" />
        </div>
      </div>
      {(showAddButton || showRedeemButton) && (
        <div className="button-container">
          {showAddButton && (
            <Button children={"Add Roses"} buttonStyle={"primary"} />
          )}
          {showRedeemButton && (
            <Button children={"Redeem Roses"} buttonStyle={"secondary"} />
          )}
        </div>
      )}
    </div>
  );
};

export default RosesDashboard;
