import React, { useState } from "react";
import "./styles.scss";
import Desk from "../../components/Wrappers/Desk";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import LogInSignUpDesk from "../../components/Wrappers/LoginSignUpDesk";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import AccountLoginPage from "../AccountLogin";
import GirlWithRosesLogoEye from "../../assets/images/girl-opened-month.png";
import { ICatalogResponse } from "../../types/catalog";
import postCreateChatContact from "../../api/postCreateChatContact";
import { useUserInfo } from "../../hooks/userInfo";
import CreateChatContact from "../../api/postCreateChatContact";
import ProfileDetails from "../../api/getProfileDetails";

const Homepage: React.FC = () => {
  const [profileAvatar, setProfileAvatar] = useState("");
  const { userInfo } = useUserInfo();
  const [isLoginPage, setIsLoginPage] = useState(true);

  const userId = userInfo.user_id || localStorage.getItem("user_id");
  const token = sessionStorage.getItem("roses-access-token");

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const { component } = useLoginSignUpNavigation();

  const catalogHandlerCallback = async (profile: ICatalogResponse) => {
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/interaction", { userId: profile.userId });
  };

  const clickProfileAvatar = () => {
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/user-settings");
  };

  const onChangeInputSearch = () => {};

  const clickStory = (userId: string) => {
    console.log(userId);
  };

  return (
    <>
      <Desk
        clickProfileAvatar={clickProfileAvatar}
        profileAvatar={profileAvatar}
        catalogHandlerCallback={catalogHandlerCallback}
        clickStory={clickStory}
        principalMain={componentPrincipal}
        secondaryMain={componentSecondary}
        tertiaryMain={componentTertiary}
        hasANewMessage={false}
        onChangeInputSearch={onChangeInputSearch}
        valueInputSearch=""
        catalogMain={mainScreen}
      />
    </>
  );
};

export default Homepage;
