import React from "react";
import "./styles.scss";
import ActivityDashboard from "../index";

const DashboardGrid: React.FC = () => {
  return (
    <div className="dashboard-grid-container">
      <ActivityDashboard
        firstContainer={true}
        title="3,450"
        subtitle="Available Roses"
      />
      <ActivityDashboard title="345" subtitle="Roses this Week" />
      <ActivityDashboard title="842" subtitle="Roses this Month" />
      <ActivityDashboard title="23,118" subtitle="All-Time Roses" />
    </div>
  );
};

export default DashboardGrid;
