import { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import RadioList from "../../components/Lists/RadioList";
import LogoEmpty from "../../assets/icons/logos/logo-empty-gray.svg";
import LogoHallow from "../../assets/icons/logos/logo-hollow-gray.svg";
import Button from "../../components/Buttons/Button";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import Toast from "../../components/Toast";

const AccountTypePage: React.FC = () => {
  const [chosenType, setChosenType] = useState("");
  const [hasError, setHasError] = useState(false);
  const { setComponent } = useLoginSignUpNavigation();
  const { setUserInfo } = useUserInfo();

  useEffect(() => {
    if (hasError) {
      setTimeout(() => {
        setHasError(false);
      }, 4000);
    }
  }, [hasError]);

  const elementsListChoose = [
    {
      inputIcon: LogoEmpty,
      inputIconAlt: "logo roses gray",
      inputTitle: "Creator",
      inputText: "Your Talent, Your Platform: Create, Share, Thrive.",
      inputValue: "creator",
      groupName: "accountType",
      sphereIcon: "pink",
      checked: chosenType === "creator",
    },
    {
      inputIcon: LogoHallow,
      inputIconAlt: "logo roses gray",
      inputTitle: "Consumer",
      inputText: "Explore Your Passions: Engage, Support, Discover.",
      inputValue: "consumer",
      groupName: "accountType",
      sphereIcon: "blue",
      checked: chosenType === "consumer",
    },
  ];

  const handleGoBack = () => {
    setComponent("/membership");
  };

  const handleNextPage = () => {
    if (!chosenType) {
      setHasError(true);
    } else {
      setUserInfo("account_type", chosenType);
      setComponent("/create-name");
    }
  };

  return (
    <LayoutDefault className="account-type">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => handleGoBack()}
          title={
            <>
              Choose <br />
              account type
            </>
          }
          subTitle="Everyone is welcomed on our platform."
        />
        <RadioList
          elements={elementsListChoose}
          onChangeValueCallback={(e) => setChosenType(e)}
        />
      </div>
      <ButtonContainer>
        <Button buttonStyle="secondary" onClick={handleNextPage}>
          Continue
        </Button>
      </ButtonContainer>

      <Toast
        type="error"
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
        description="You need to choose an account type to continue."
      />
    </LayoutDefault>
  );
};

export default AccountTypePage;
