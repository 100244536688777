import React from "react";
import "./styles.scss";
import HeartPink from "../../../assets/icons/heartIcons/heart-pink.svg";
import HeartWhite from "../../../assets/icons/heartIcons/heart-white.svg";
import XIconCharcoal from "../../../assets/icons/closeIcons/close-icon-charcoal.svg";
import XIconGray from "../../../assets/icons/closeIcons/close-icon-gray.svg";
import XIconPink from "../../../assets/icons/closeIcons/close-icon-pink.svg";

interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle:
    | "x-gradient"
    | "x-background-transparent"
    | "x-background-charcoal"
    | "x-background-white"
    | "heart"
    | "heart-white"
    | "heart-white-gradient";
}

const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
  const { buttonStyle } = props;

  const switchButtonStyle = () => {
    switch (buttonStyle) {
      case "x-gradient":
        return { icon: "", alt: "", className: "x-gradient" };
      case "x-background-transparent":
        return {
          icon: XIconPink,
          alt: "close icon",
          className: "x-background-transparent",
        };
      case "x-background-charcoal":
        return {
          icon: XIconGray,
          alt: "close icon",
          className: "x-background-charcoal",
        };
      case "x-background-white":
        return {
          icon: XIconCharcoal,
          alt: "",
          className: "x-background-white",
        };
      case "heart":
        return { icon: HeartPink, alt: "heart", className: "heart" };
      case "heart-white":
        return { icon: HeartWhite, alt: "heart", className: "heart-white" };
      case "heart-white-gradient":
        return {
          icon: HeartWhite,
          alt: "heart",
          className: "heart-white-gradient background-blur-gray",
        };
    }
  };

  return (
    <button id="button-icon" className={switchButtonStyle().className}>
      <img src={switchButtonStyle().icon} alt={switchButtonStyle().alt} />
    </button>
  );
};

export default ButtonIcon;
