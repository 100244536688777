import React, { ReactNode, useState } from "react";
import "./styles.scss";

interface RangeProps {}

const Range: React.FC<RangeProps> = (props) => {
  const {} = props;
  const [rangeValue, setRangeValue] = useState("");

  console.log(rangeValue);
  return (
    <div id="range-default">
      <input
        type="range"
        id="range_val"
        min={0}
        max={100}
        value={rangeValue}
        onChange={(e) => setRangeValue(e.target.value)}
      />
    </div>
  );
};

export default Range;
