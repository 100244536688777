import { useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import Toast from "../../components/Toast";
import { IToast } from "../../types/toast";

const RecoveryEmailPage: React.FC = () => {
  const [userRecoveryEmail, setUserRecoveryEmail] = useState("");
  const [isSignNewsLetter, setIsSignNewsLetter] = useState(true);
  const [emailInputWarning, setInputWaning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const { setPrincipalScreen } = useDeskNavigation();
  const { setComponent } = useLoginSignUpNavigation();
  const { setUserInfo } = useUserInfo();

  const validateEmail = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const recoveryEmailSubmit = () => {
    if (validateEmail(userRecoveryEmail)) {
      setUserInfo("user_email", userRecoveryEmail);
      setComponent("/allow-location");
    } else {
      setToastConfig({
        type: "error",
        title: "invalid email",
        description: "Add a valid email",
      });

      setToastShow(true);
      setInputWaning(true);

      setTimeout(() => {
        setToastShow(false);
        setInputWaning(false);
      }, 3000);
    }
  };

  return (
    <LayoutDefault className="recovery-email">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => setPrincipalScreen("/create-password")}
          title={
            <>
              Set a <br />
              recovery email
            </>
          }
          subTitle="Your e-mail is used to recover your account."
        />
        <InputText
          value={userRecoveryEmail.toLowerCase()}
          inputType="email"
          placeholder="Email"
          textTransform="none"
          onChangeInput={(e) => setUserRecoveryEmail(e)}
          isWarning={emailInputWarning}
        />
        <SubText
          checkAnswer
          checkValueCallback={setIsSignNewsLetter}
          isChecked={isSignNewsLetter}
        >
          I want to receive news, updates, and offers from Roses
        </SubText>
      </div>
      <ButtonContainer>
        <Button buttonStyle={"primary"} onClick={recoveryEmailSubmit}>
          Continue
        </Button>
      </ButtonContainer>
      <Toast
        type={toastConfig.type}
        title={toastConfig.title}
        description={toastConfig.description}
        isVisible={toastShow}
        setIsVisible={setToastShow}
      />
    </LayoutDefault>
  );
};

export default RecoveryEmailPage;
