import React, { ReactNode } from "react";
import "./styles.scss";

interface CategoryProps {
  children: ReactNode;
}

const Category: React.FC<CategoryProps> = (props) => {
  const { children } = props;
  return <div id="category-default">{children}</div>;
};

export default Category;
