import React from "react";
import Photo from "../../assets/icons/photo-gray.svg";
import Mic from "../../assets/icons/mic-gray.svg";
import Roses from "../../assets/icons/roses-gray.svg";
import Video from "../../assets/icons/video-gray.svg";
import Phone from "../../assets/icons/phone-gray.svg";
import "./styles.scss";

const ACTIONS = [
  {
    title: "Photo",
    icon: Photo,
    onClick: () => console.log("photo"),
  },
  {
    title: "Audio",
    icon: Mic,
    onClick: () => console.log("Audio"),
  },
  {
    title: "Roses",
    icon: Roses,
    onClick: () => console.log("roses"),
  },
  {
    title: "Video",
    icon: Video,
    onClick: () => console.log("video"),
  },
  {
    title: "Phone",
    icon: Phone,
    onClick: () => console.log("phone"),
  },
];

const ActionInputPopOut: React.FC = () => {
  return (
    <div id="actions-bar">
      {ACTIONS.map((action, index) => (
        <div key={index} className="action-container">
          <img
            src={action.icon}
            alt="Action Icon"
            className="action-icon"
            onClick={action.onClick}
          />
        </div>
      ))}
    </div>
  );
};

export default ActionInputPopOut;
