import { useState } from "react";
import Header from "../../components/Headers/Header";
import CodeVerification from "../../components/CodeVerification";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";

import ClockIcon from "../../assets/icons/clock-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";

const CreateEmailVerifyPage: React.FC = () => {
  const [codeValue, setCodeValue] = useState("");

  const addEmailSubmit = () => {};
  return (
    <LayoutDefault className="create-email-verify">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => {}}
          title={
            <>
              Verify your <br />
              Email
            </>
          }
          subTitle={
            <>
              Enter the code we’ve sent by [SMS/WhatsApp] to
              <span className="email-text">name@domain.com</span>
              <a href="/">Change</a>
            </>
          }
        />
        <CodeVerification
          fieldsNumber={5}
          valuesCodesCallback={(e) => setCodeValue(e)}
          isWarning
          warningText="You’ve entered an invalid code"
        />
        <SubText icon={ClockIcon} altIcon="clock icon">
          This code should arrive within 30 seconds.
        </SubText>
      </div>

      <ButtonContainer>
        <Button buttonStyle="primary" onClick={addEmailSubmit}>
          Continue
        </Button>
      </ButtonContainer>
    </LayoutDefault>
  );
};

export default CreateEmailVerifyPage;
