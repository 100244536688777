import CardsCatalogDetails from "../Cards/CardsCatalogDetails";
import "./styles.scss";
import girl1 from "../../assets/images/membership/pre-step.png";
import girl2 from "../../assets/images/membership/step-four.png";
import girl3 from "../../assets/images/membership/step-six.png";
import girl4 from "../../assets/images/landingPageImages/girl-features-third.svg";
import ListItem from "../Lists/ListItem";
import CardsMediaLibrary from "../Cards/CardsMediaLibrary";
import girl1library from "../../assets/cardsLibraryImages/image.png";
import girl2library from "../../assets/cardsLibraryImages/image (1).png";
import girl3library from "../../assets/cardsLibraryImages/image (1).png";
import girl4library from "../../assets/cardsLibraryImages/image (3).png";
import girl5library from "../../assets/cardsLibraryImages/image (4).png";
import girl6library from "../../assets/cardsLibraryImages/image (5).png";
import girl7library from "../../assets/cardsLibraryImages/image (6).png";
import girl8library from "../../assets/cardsLibraryImages/image (7).png";
import girl9library from "../../assets/cardsLibraryImages/image (8).png";
import PageTitle from "../../components/PageTitles";
import Button from "../Buttons/Button";
import ButtonIcon from "../Buttons/ButtonIcon";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import { useCallback, useEffect, useState } from "react";
import ProfileDetails from "../../api/getProfileDetails";
import { ICatalogResponse } from "../../types/catalog";
import CreateChatContact from "../../api/postCreateChatContact";
import { useWebSocket } from "../../contexts/socket/webSocketContext";
import { Chat } from "../../pages/Chat/ChatList/types";

const ProducerInteractionPage: React.FC = () => {
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    params,
  } = useDeskNavigation();
  const { getChats } = useWebSocket();
  const [chats, setChats] = useState<Chat[]>([]);
  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[] | []>([]);
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>({
    displayname: "",
    isFavorite: false,
    profile: {
      age: 0,
      gender: "",
      height: 0,
      location: "",
      maritalStatus: "",
      occupation: "",
      photos: [],
      profileDetails: [],
      rating: 0,
      weight: 0,
    },
    userId: "",
  });

  const { userId } = params;
  const token = sessionStorage.getItem("roses-access-token");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [params]);

  useEffect(() => {
    const producerDetail = async () => {
      if (token) {
        const response = await ProfileDetails(userId, token);

        switch (response?.status) {
          case 200:
            console.log("producerDetail", response.res);
            setProducerDetails(response.res);

            break;
          default:
            // setPrincipalScreen("");
            break;
        }
      }
    };
    producerDetail();
  }, [userId]);

  const heartButtonClick = () => {};

  const getListChats = useCallback(async () => {
    await getChats({}, (res) => {
      setOpenedChatUserIds(
        res?.map((chat: Chat) => chat?.chatParticipants[0]?.user?.userId)
      );
      setChats(res);
    });
  }, [getChats]);

  useEffect(() => {
    getListChats();
  }, [getChats, getListChats]);

  console.log("><><><", chats);

  const sendMessageClick = async () => {
    if (token && userId && openedChatUserIds.length) {
      //@ts-ignore]
      const isOpenedChat = openedChatUserIds?.includes(userId);
      console.log(isOpenedChat);

      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        userId,
        token
      );

      switch (response.status) {
        case 201:
          console.log(response.res);

          break;

        default:
          break;
      }
      setSecondaryScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId,
        isLocked: JSON.stringify(isOpenedChat),
      });
    }
  };

  const detailsMock = {
    gender: producerDetails.profile.gender,
    age: producerDetails.profile.age,
    ethnicity: "Brazilian*",
    eye: "Hazel*",
    hairColor: "Brunette*",
    height: producerDetails.profile.height,
    weight: producerDetails.profile.weight,
    rosesToConnect: "10*",
    rosesToMeet: "200*",
  };

  const cardsLibrary = producerDetails.profile.photos.map((photo, index) => {
    return {
      id: index,
      img: photo.url,
      price: index % 2 === 0 ? "0" : "11",
      alt: "girl",
    };
  });

  return (
    <div id="producer-interaction">
      <PageTitle
        onBackClick={() => {
          setPrincipalScreen("");
          setSecondaryScreen("");
          setTertiaryScreen("");
        }}
      />
      <CardsCatalogDetails
        age={producerDetails?.profile.age}
        isVerified
        isVip
        name={producerDetails.displayname}
        principalPhoto={producerDetails.profile.photos[0]?.url}
        profileDetails={["Brazilian", "5’4” / 1,67m", "56kg / 123lb"]}
        rating={producerDetails.profile.rating || 4}
        heartButtonClick={heartButtonClick}
        sendMessageClick={sendMessageClick}
      />

      <div className="list-items-details">
        {Object.entries(detailsMock).map(([detail, value], index) => (
          <ListItem
            key={index}
            showLabel
            labelContent={
              detail === "rosesToConnect"
                ? "Roses to Connect"
                : detail === "rosesToMeet"
                ? "Roses to Meet"
                : detail === "hairColor"
                ? "Hair Color"
                : detail.charAt(0).toUpperCase() + detail.slice(1)
            }
            showValue
            valueContent={value || ""}
            showSeparator={Object.entries(detailsMock).length > index + 1}
          />
        ))}
      </div>

      <div className="photo-album">
        {cardsLibrary.map((card, index) => (
          <CardsMediaLibrary
            key={index}
            image={card.img}
            altImage={card.alt}
            rosesPrice={card.price}
            unlockClick={() => console.log(index)}
            isBlurred={parseFloat(card.price) > 0}
          />
        ))}
      </div>

      <div className="reviews-wrapper">
        <div className="header-review-wrapper">
          <img src={LockIcon} alt="lock icon" />
          <p>REVIEWS</p>
          <p>
            Begin interaction <br /> to see user's reviews
          </p>
        </div>

        <ListItem
          showLabel
          labelContent="Connect & Chat"
          showValue
          valueContent={detailsMock.rosesToConnect}
          showSeparator
        />
        <ListItem
          showLabel
          labelContent="Virtual Meetup"
          showValue
          valueContent={detailsMock.rosesToMeet}
        />
        <div className="footer-interaction-divider" />
        <div className="buttons-interaction-container">
          <Button buttonStyle="primary" onClick={sendMessageClick}>
            Send Message
          </Button>
          <ButtonIcon
            onClick={heartButtonClick}
            buttonStyle="heart-white-gradient"
          />
        </div>
      </div>
    </div>
  );
};

export default ProducerInteractionPage;
