import React, { ChangeEvent, useEffect, useState } from "react";
import "./styles.scss";
import VMasker from "vanilla-masker";
import Loupe from "../../../assets/icons/loupe-gray.svg";

interface InputTextProps {
  inputStyle?: string;
  mask?: string;
  value?: string;
  onChangeInput?: (a: string) => void;
  iconLeft?: string;
  iconRight?: string;
  label?: string;
  inputType?: React.HTMLInputTypeAttribute | undefined;
  searchInput?: boolean;
  textAlign?: "start" | "end" | "left" | "right" | "center";
  isWarning?: boolean;
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "full-width"
    | "full-size-kana";
  placeholder?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean | undefined;
  className?: string;
}

const InputText: React.FC<InputTextProps> = (props) => {
  const {
    inputStyle,
    value,
    mask,
    onChangeInput,
    iconRight,
    iconLeft,
    label,
    searchInput,
    textAlign,
    inputType,
    isWarning,
    textTransform = "capitalize",
    placeholder,
    onKeyDown,
    disabled,
    className,
  } = props;

  const handlerInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (mask) {
      onChangeInput?.(VMasker.toPattern(inputValue, mask));
    } else {
      onChangeInput?.(inputValue);
    }
  };

  return (
    <div id="input-text-default">
      {label && <p className="label-input">{label}</p>}
      <div className="input-container">
        {searchInput ? (
          <img className="input-icon-left" src={Loupe} alt="loupe icon" />
        ) : iconLeft ? (
          <img className="input-icon-left" src={iconLeft} alt="" />
        ) : (
          false
        )}
        <input
          disabled={disabled}
          className={`${className} ${
            isWarning ? " isWarning" : ""
          }  " input-text " + ${inputStyle}`}
          value={value}
          onChange={handlerInput}
          type={inputType ?? "text"}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          style={{
            textAlign: textAlign,
            paddingRight: searchInput || iconRight ? 40 : 16,
            paddingLeft: searchInput || iconLeft ? 40 : 16,
            textTransform: textTransform,
          }}
        />
        {iconRight && (
          <img className="input-icon-right" src={iconRight} alt="right icon" />
        )}
      </div>
    </div>
  );
};

export default InputText;
