import React, { ReactNode } from "react";
import "./styles.scss";

interface ActivityDashboardProps {
  title: ReactNode;
  subtitle: ReactNode;
  firstContainer?: boolean;
}

const ActivityDashboard: React.FC<ActivityDashboardProps> = ({
  title = "0",
  subtitle = "Activity Title",
  firstContainer = false,
}) => {
  return (
    <div
      className={`activity-dashboard-container ${
        firstContainer ? "first-container" : ""
      }`}
    >
      <div className="text-container">
        <div className="title-text">{title}</div>
        <div className="subtitle-text">{subtitle}</div>
      </div>
    </div>
  );
};

export default ActivityDashboard;
