import React, { ChangeEvent, useState } from "react";
import "./styles.scss";
import Camera from "../../assets/icons/camera-icon-gray.svg";
import CloseIconPeach from "../../assets/icons/closeIcons/close-icon-peach.svg";

interface PhotosPlaceholderProps {
  quantityFiles: number;
  photoAlbumCallback: (files: (File | null)[]) => void;
}

const PhotosPlaceholder: React.FC<PhotosPlaceholderProps> = (props) => {
  const { quantityFiles, photoAlbumCallback } = props;
  const [files, setFiles] = useState<(File | null)[]>(
    Array(quantityFiles).fill(null)
  );

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newFiles = [...files];
    if (e.target.files && e.target.files[0]) {
      newFiles[index] = e.target.files[0];
      setFiles(newFiles);
      photoAlbumCallback(newFiles);
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles[index] = null;
    setFiles(newFiles);
    photoAlbumCallback(newFiles);
  };

  return (
    <div id="photos-placeholder">
      {files.map((file, index) => (
        <div className="photo-preview" key={index}>
          {!file && (
            <span>
              <img src={Camera} alt="camera icon" />
            </span>
          )}

          <input
            type="file"
            name="upload-photo"
            onChange={(e) => handleFileChange(e, index)}
          />
          {file && (
            <div className="preview-images">
              <button onClick={() => handleRemoveFile(index)}>
                <img src={CloseIconPeach} alt="remove icon" />
              </button>
              <img src={URL.createObjectURL(file)} alt={`preview ${index}`} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PhotosPlaceholder;
