import React, { ReactNode } from "react";
import "./styles.scss";

import Button from "../../Buttons/Button";
import infoGrayIcon from "../../../assets/icons/union-gray.svg";
import ListItem from "../../Lists/ListItem";
import InputText from "../../Inputs/InputText";

const AutoRecharge: React.FC = () => {
  return (
    <div className="auto-recharge-container">
      <ListItem
        showLabel={true}
        labelContent="Auto-recharge Credits"
        showDescription={true}
        descriptionContent="Automatically recharge rose credits."
        showToggle={true}
      />
      <div className="input-container">
        <div className="input-wrapper">
          <InputText
            label="Low balance amount"
            value={"$25"}
            onChangeInput={() => {}}
            placeholder="Phone number"
            inputType="tel"
          />
        </div>
        <div className="input-wrapper">
          <InputText
            label="Auto-recharge amount"
            value={"$100"}
            onChangeInput={() => {}}
            placeholder="Phone number"
            inputType="tel"
          />
        </div>
      </div>
      <Button children={"Apply"} buttonStyle={"tertiary"} />
    </div>
  );
};

export default AutoRecharge;
