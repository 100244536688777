import { useRef, useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import DocumentPlaceholder from "../../components/DocumentPlaceholder";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";

const UploadDocumentPage: React.FC = () => {
  const [documentFileFront, setDocumentFileFront] = useState<File | null>(null);
  const [documentFileBack, setDocumentFileBack] = useState<File | null>(null);
  const captureDocument = () => {};

  const fileInputRefFront = useRef<HTMLInputElement>(null);
  const fileInputRefBack = useRef<HTMLInputElement>(null);

  const documentFromGallery = () => {
    if (documentFileFront === null) {
      fileInputRefFront.current?.click();
    } else if (documentFileBack === null) {
      fileInputRefBack.current?.click();
    } else {
      return;
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (documentFileFront === null) {
        setDocumentFileFront(file);
      } else if (documentFileBack === null) {
        setDocumentFileBack(file);
      } else {
        return;
      }
    }
  };

  console.log(documentFileFront, documentFileBack);

  return (
    <LayoutDefault className="upload-document">
      <div>
        <Header
          title={"Upload your document"}
          subTitle={`Please upload a document for identity verification.
					 Ensure it's legible and up-to-date for a smooth verification
					 process.`}
        />

        <DocumentPlaceholder
          docBackCallback={setDocumentFileBack}
          docFrontCallback={setDocumentFileFront}
          documentFileFront={documentFileFront}
          documentFileBack={documentFileBack}
        />
        <input
          ref={fileInputRefFront}
          onChange={handleFileChange}
          type="file"
          name="document"
          style={{ display: "none" }}
        />

        <input
          ref={fileInputRefBack}
          onChange={handleFileChange}
          type="file"
          name="document"
          style={{ display: "none" }}
        />
      </div>

      <ButtonContainer>
        <Button buttonStyle="quaternary" onClick={documentFromGallery}>
          Photo Album
        </Button>
        <Button buttonStyle="secondary" onClick={captureDocument}>
          Capture Document
        </Button>
      </ButtonContainer>
    </LayoutDefault>
  );
};

export default UploadDocumentPage;
