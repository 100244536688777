import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import image1 from "../../assets/images/landingPageImages/banner/roses_banner_11.jpg";
import image2 from "../../assets/images/landingPageImages/banner/roses_banner_01.jpg";
import image3 from "../../assets/images/landingPageImages/banner/roses_banner_02.jpg";
import image4 from "../../assets/images/landingPageImages/banner/roses_banner_03.jpg";
import image5 from "../../assets/images/landingPageImages/banner/roses_banner_04.jpg";
import image6 from "../../assets/images/landingPageImages/banner/roses_banner_17.jpg";
import image7 from "../../assets/images/landingPageImages/banner/roses_banner_19.jpg";
import logoRoses from "../..//assets/icons/logos/logo-fill-pink.svg";
import sparkIconPink from "../../assets/icons/bannerIcons/spark-icon-pink.svg";
import chatIconPink from "../../assets/icons/bannerIcons/chat-icon-pink.svg";
import cryptoIconPink from "../../assets/icons/bannerIcons/crypto-icon-pink.svg";
import globeIconPink from "../../assets/icons/bannerIcons/globe-icon-pink.svg";
import rosesTokenIconPink from "../../assets/icons/bannerIcons/roses-token-icon-pink.svg";
import securityIconPink from "../../assets/icons/bannerIcons/security-icon-pink.svg";
import SlideCarousel from "../../components/Carousels/SlideCarousel";
import Header from "../../components/Headers/Header";

const Membership: React.FC = () => {
  const [showSlide, setShowSlide] = useState(false);
  const [initialVisible, setInitialVisible] = useState(true);
  const [slideVisible, setSlideVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { setComponent } = useLoginSignUpNavigation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleGoBack = () => {
    setComponent("/account-login");
  };

  const handleGotoTerms = useCallback(() => {
    setComponent("/terms-and-conditions");
  }, [setComponent]);

  const slides = [
    {
      id: 0,
      backgroundImage: image1,
      title: "Confirmation",
      subtitle: `By tapping 'Confirm', you acknowledge that you are over the age 
			of 18 and agree to our Terms of Services. Learn how we process your data in 
			our Privacy Policy.`,
      icon: logoRoses,
    },
    {
      id: 1,
      backgroundImage: image2,
      title: "Fuel your <br><strong>creative spark</strong>",
      subtitle: `Harness powerful features to elevate your content, increase
				 discoverability, and maximize your impact`,
      icon: sparkIconPink,
    },
    {
      id: 2,
      backgroundImage: image3,
      title: "Expand your<br><strong> global influence</strong>",
      subtitle: `Use auto-translation chat for seamless interaction with fans
			 worldwide, breaking language barriers to enhance your global reach`,
      icon: chatIconPink,
    },
    {
      id: 3,
      backgroundImage: image4,
      title: "Trust and <br><strong>transparency</strong>",
      subtitle: `Enjoy a secure, private environment where you retain full 
			control over your content and who sees it, ensuring peace of mind`,
      icon: securityIconPink,
    },
    {
      id: 4,
      backgroundImage: image5,
      title: "Share<br><strong>your world</strong>",
      subtitle: `Highlight a part of yourself, showcasing your true essence 
			without limitations`,
      icon: globeIconPink,
    },
    {
      id: 5,
      title: "Cherished our<br><strong>tokens of devotion</strong>",
      subtitle: `Treasure the experience of receiving Roses, elevating each
			 interaction with tokens of admiration`,
      backgroundImage: image6,
      icon: rosesTokenIconPink,
    },
    {
      id: 6,
      title: "Earn more<br><strong>with crypto</strong>",
      subtitle: `Dive into crypto earnings to amplify your possibilities and 
			maximize your creative earnings`,
      backgroundImage: image7,
      icon: cryptoIconPink,
    },
  ];

  const continueHandler = () => {
    if (windowWidth > 768) {
      skipHandler();
    } else {
      setInitialVisible(false);
      setTimeout(() => setShowSlide(true), 200);
      setTimeout(() => setSlideVisible(true), 200);
    }
  };

  const skipHandler = () => {
    setComponent("/account-type");
  };

  return (
    <div className="membership-page">
      {windowWidth > 768 ? (
        <div className="display-membership-widescreen">
          <div className="initial-text-container-wide">
            <Header
              headerIcon="back-button"
              backButtonClick={() => handleGoBack()}
              title={slides[0].title}
              subTitle={slides[0].subtitle}
            />

            <div className="initial-buttons-container-wide">
              <Button buttonStyle="primary" onClick={continueHandler}>
                Confirm
              </Button>
              <Button buttonStyle="quaternary" onClick={handleGoBack}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {showSlide ? (
            <div
              className={`slide-carousel-container ${
                slideVisible ? "visible" : "hidden"
              }`}
            >
              <SlideCarousel
                slides={slides.filter((slide) => slide.id !== 0)}
                lastSlideButtonOnClick={skipHandler}
                lastSlideButtonStyle="primary"
                lastSlideTextButton={"Get Started"}
                subButtonOnClick={skipHandler}
                subButtonStyle="quaternary"
                subTextButton="Skip"
                hasArrows={false}
              />
            </div>
          ) : (
            <div
              className={`initial-image-slide-text-container ${
                initialVisible ? "visible" : "hidden"
              }`}
            >
              <img src={slides[0].backgroundImage} alt="introduction slide" />
              <div className="initial-text-container">
                <img src={slides[0].icon} alt="Icon" />
                <h2 dangerouslySetInnerHTML={{ __html: slides[0].title }} />
                <p dangerouslySetInnerHTML={{ __html: slides[0].subtitle }} />
                <div className="initial-buttons-container">
                  <Button buttonStyle="primary" onClick={continueHandler}>
                    Confirm
                  </Button>
                  <Button buttonStyle="quaternary" onClick={handleGoBack}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Membership;
