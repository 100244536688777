import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../../components/Wrappers/LayoutDefault";
import PageTitle from "../../../components/PageTitles";
import ListItem from "../../../components/Lists/ListItem";
import ButtonNavigation from "../../../components/Buttons/ButtonNavigation";
import InputText from "../../../components/Inputs/InputText";
import chevronRightIcon from "../../../assets/icons/navigationIcons/bx-chevron-right.svg";
import { useWebSocket } from "../../../contexts/socket/webSocketContext";
import { Chat } from "./types";
import { useNavigate } from "react-router-dom";
import { useDeskNavigation } from "../../../hooks/useDeskNavigation";

const ChatListPage: React.FC = () => {
  const { getChats } = useWebSocket();
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [chats, setChats] = useState<Chat[]>([]);
  const navigate = useNavigate();
  console.log(chats);
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
  } = useDeskNavigation();

  const getListChats = useCallback(async () => {
    await getChats({}, (res) => {
      setChats(res);
    });
  }, [getChats]);

  useEffect(() => {
    getListChats();
  }, [getChats, getListChats]);

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const handleChat = (chatId: string) => {
    setPrincipalScreen("/personal-chat", { chatId });
  };

  return (
    <div className="chat-list">
      <div className="top-container">
        <PageTitle
          title={"Messages"}
          subtitle={"Conversation"}
          onBackClick={() => setPrincipalScreen("")}
        />
        <InputText
          value={"Search"}
          placeholder="Search"
          onChangeInput={() => {}}
          searchInput
        />
        <ButtonNavigation
          setActiveSection={handleSectionChange}
          activeSection={activeSection}
          buttonCount={2}
          buttonLabels={["Messages", "Notifications"]}
        />
      </div>
      {activeSection === "button1" && (
        <>
          <div className="input-container"></div>
          {chats.map((chat) => (
            <ListItem
              key={chat.chatId}
              clickAction={() => handleChat(chat.chatId)}
              showLabel
              labelContent={chat.chatParticipants[0]?.user.displayname}
              showIconLeft
              showIconBorderLeft
              showDescription
              descriptionContent={chat.messages[0]?.content}
              showIconRight
              iconRightContent={chevronRightIcon}
              showSeparator
            />
          ))}
        </>
      )}

      {activeSection === "button2" && <></>}
    </div>
  );
};

export default ChatListPage;
