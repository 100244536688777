import { servicesApi } from "./services-api";

const patchMembershipInfo = async (userId: string, data: any) => {
  const apiRoute = `${servicesApi("applicationUserInfo")}${userId}`;

  try {
    const response = await fetch(apiRoute, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export default patchMembershipInfo;
