import React, { useEffect, useState } from "react";
import InputText from "../Inputs/InputText";
import Select from "../Selects/Select";
import codesMock from "../../mocks/countryCodes.json";
import "./styles.scss";
import { setupPhoneNumberInput } from "../../utils/masks";
import { CountryCode } from "libphonenumber-js";
import ipInfoApi from "../../api/getUserIpInfo";

interface CodeAndPhoneNumberProps {
  inputValueCallback: (a: string) => void;
  setCountryCode: (a: string) => void;
  isInputWarning?: boolean;
  isSelectWarning?: boolean;
}

const CodeAndPhoneNumber: React.FC<CodeAndPhoneNumberProps> = (props) => {
  const {
    inputValueCallback,
    setCountryCode,
    isInputWarning,
    isSelectWarning,
  } = props;
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCodeByIp, setCountryCodeByIp] = useState<string | undefined>(
    undefined
  );

  const inputPhoneHandler = (e: string) => {
    if (phoneCode) {
      let codeCountry = JSON.parse(phoneCode);

      const formattedNumber = setupPhoneNumberInput(
        codeCountry.code as CountryCode,
        e
      );
      setPhoneNumber(formattedNumber);

      setCountryCode(codeCountry.countryCode);
      inputValueCallback(`${e.replace(/\D/g, "")}`);
    } else {
      setPhoneNumber(e);
      inputValueCallback(e.replace(/\D/g, ""));
    }
  };

  useEffect(() => {
    const getCountryByIp = async () => {
      try {
        const data = await ipInfoApi();
        if (data && data.country) {
          const countryItem = codesMock.find(
            (item) => item.code === data.country
          );
          if (countryItem) {
            setPhoneCode(JSON.stringify(countryItem));
            setCountryCode(countryItem.countryCode);
            setCountryCodeByIp(JSON.stringify(countryItem));
          }
        }
      } catch (error) {
        console.error("Erro ao buscar dados da API ipInfoApi:", error);
      }
    };

    getCountryByIp();
  }, [setCountryCode]);

  return (
    <div id="code-and-phone-number">
      <Select
        options={codesMock.map((code) => {
          return {
            label: `${code.flag} ${code.code} ${code.countryCode}`,
            value: JSON.stringify(code),
          };
        })}
        onChange={(e) => setPhoneCode(e.target.value)}
        value={countryCodeByIp}
        defaultValueText={"Select"}
        isWarning={isSelectWarning}
      />
      <InputText
        value={phoneNumber}
        onChangeInput={inputPhoneHandler}
        placeholder="Phone number"
        inputType="tel"
        isWarning={isInputWarning}
      />
    </div>
  );
};

export default CodeAndPhoneNumber;
