import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header from "../../components/Headers/Header";
import PageTitle from "../../components/PageTitles";

import "./styles.scss";
import ChatLocked from "../../components/ChatLocked";
import ChatMessage from "../../components/ChatMessage";
import InputText from "../../components/Inputs/InputText";
import ActionInputPopOut from "../../components/ActionInputPopOut";
import { useWebSocket } from "../../contexts/socket/webSocketContext";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { format } from "date-fns";
import { Chat } from "../Chat/ChatList/types";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ProfileDetails from "../../api/getProfileDetails";
import { ICatalogResponse } from "../../types/catalog";
import CreateChatContact from "../../api/postCreateChatContact";
import { useUserInfo } from "../../hooks/userInfo";

interface Message {
  messageId: "string";
  userId: "string";
  chatId: "string";
  content: "string";
  isRead: boolean;
  expiresAt: "Date";
  createdAt: "Date";
  updatedAt: "Date";
}

type IMessagePayload = {
  eventType: string;
  payload: Message;
};

const ChatPage: React.FC = () => {
  const { addChatListener, removeListener, sendMessage, getMessages, getChat } =
    useWebSocket();
  const [chatLocked, setChatLocked] = React.useState<boolean>(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [chat, setChat] = useState<Chat>();
  const didRef = useRef(false);

  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>({
    displayname: "",
    isFavorite: false,
    profile: {
      age: 0,
      gender: "",
      height: 0,
      location: "",
      maritalStatus: "",
      occupation: "",
      photos: [],
      profileDetails: [],
      rating: 0,
      weight: 0,
    },
    userId: "",
  });
  const { user } = useAuth();
  const { userInfo } = useUserInfo();

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    params,
  } = useDeskNavigation();

  const { chatId, userId, isLocked } = params;
  const userIdLogged = userInfo.user_id || localStorage.getItem("user_id");
  const token = sessionStorage.getItem("roses-access-token");
  const rosesPerMessage = 2;

  useEffect(() => {
    producerDetail();

    const isChatLocked = isLocked === "false" ? false : true;

    setChatLocked(!isChatLocked);
  }, [userId]);

  useEffect(() => {
    if (!didRef.current) {
      didRef.current = true;
      addChatListener(handleNewMessage);
      handleMessages();
      getInChat();
      return () => {
        removeListener("NEW_MESSAGE", handleNewMessage);
      };
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const participant = useMemo(() => {
    if (chat?.chatParticipants) {
      return chat?.chatParticipants?.find(
        (participant) => participant.user.userId !== user.userID
      )?.user;
    }
  }, [chat, user.userID]);

  const getInChat = async () => {
    getChat(chatId, setChat);
  };

  const producerDetail = useCallback(async () => {
    if (token) {
      const response = await ProfileDetails(userId, token);

      switch (response?.status) {
        case 200:
          setProducerDetails(response.res);
          break;
        default:
          // setPrincipalScreen("");
          break;
      }
    }
  }, []);

  const handleMessages = useCallback(async () => {
    const messageParams = {
      chatId,
      take: 10,
    };

    await getMessages(messageParams, (messages) => {
      setMessages(messages);
    });
  }, [chatId, getMessages]);

  const handleNewMessage = (message: IMessagePayload) => {
    if (message.eventType === "NEW_MESSAGE") {
      setMessages((prevMessages) => [...prevMessages, message.payload]);
    }
  };

  const handleSendMessage = async (text: string) => {
    sendMessage({ chatId, content: text }, (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      setNewMessage("");
      setLoading(false);
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!newMessage) return;
      setLoading(true);
      handleSendMessage(newMessage);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const formattedTime = (date: string) => format(date, "HH:mm");

  const openMediaLibrary = () => {
    setTertiaryScreen("/media-library", { userId });
  };

  const handleCreateChatContact = async () => {
    setChatLocked(false);
    await CreateChatContact().postCreateChatContact(userId, token as string);
  };

  return (
    <div className="chat-container-page">
      {/* <Header headerIcon="roses-logo" marginTop={0} /> */}
      <PageTitle
        isChat
        title={producerDetails?.displayname}
        subtitle={"Online*"}
        avatarUrl={producerDetails?.profile?.photos?.[0]?.url}
        onBackClick={() => {
          setSecondaryScreen("");
          setTertiaryScreen("");
        }}
        isOnLine={true}
        mediaButtonClick={openMediaLibrary}
      />
      {chatLocked ? (
        <ChatLocked onClickButton={handleCreateChatContact} />
      ) : (
        <div className="messages-container">
          {messages?.map((message) => (
            <ChatMessage
              key={message.messageId}
              side={userIdLogged === message.userId ? "right" : "left"}
              message={message.content}
              time={formattedTime(message.createdAt)}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      )}
      <div className="actions-input-chat">
        <ActionInputPopOut />
        <InputText
          className="input-chat"
          value={newMessage}
          onChangeInput={setNewMessage}
          onKeyDown={handleKeyDown}
          disabled={loading}
          placeholder={`$ ${rosesPerMessage} per message`}
        />
      </div>
    </div>
  );
};

export default ChatPage;
