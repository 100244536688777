import { useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import Toast from "../../components/Toast";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { IToast } from "../../types/toast";

const CreatePasswordPage: React.FC = () => {
  const [createdPassword, setCreatedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const { setComponent } = useLoginSignUpNavigation();

  const confirmPasswordSubmit = () => {
    if (createdPassword.length >= 8 && confirmPassword.length >= 8) {
      if (createdPassword === confirmPassword) {
        sessionStorage.setItem("pass", createdPassword);
        setComponent("/recovery-email");
      } else {
        setToastConfig({
          description: "The password and confirm password do not match.",
          title: "Password Mismatch",
          type: "error",
        });
        setPasswordInputWarning(true);
        setToastShow(true);

        setTimeout(() => {
          setToastShow(false);
          setPasswordInputWarning(false);
        }, 3000);
      }
    } else {
      setToastConfig({
        description: "Your password should be at least 8 characters long",
        title: "Password too short",
        type: "error",
      });
      setToastShow(true);
      setPasswordInputWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setPasswordInputWarning(false);
      }, 3000);
    }
  };

  return (
    <LayoutDefault className="create-password">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => setComponent("/welcome-user")}
          title={
            <>
              Set a <br />
              password
            </>
          }
          subTitle={
            <>
              Your password should be at least <br /> 8 characters long
            </>
          }
        />
        <InputText
          value={createdPassword}
          placeholder="password"
          inputType="password"
          onChangeInput={(e) => setCreatedPassword(e)}
          isWarning={passwordInputWarning}
        />
        <InputText
          value={confirmPassword}
          placeholder="confirm password"
          inputType="password"
          onChangeInput={(e) => setConfirmPassword(e)}
          isWarning={passwordInputWarning}
        />
        <SubText icon={LockIcon} altIcon="clock icon">
          Never share your credentials with anyone.
        </SubText>
      </div>

      <ButtonContainer>
        <Button buttonStyle={"primary"} onClick={confirmPasswordSubmit}>
          Confirm
        </Button>
      </ButtonContainer>
      <Toast
        type={toastConfig.type}
        title={toastConfig.title}
        description={toastConfig.description}
        isVisible={toastShow}
        setIsVisible={setToastShow}
      />
    </LayoutDefault>
  );
};

export default CreatePasswordPage;
