import React from "react";
import Verify from "../../../assets/icons/verify.svg";
import "./styles.scss";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import Button from "../../Buttons/Button";
import ButtonIcon from "../../Buttons/ButtonIcon";
import Rating from "../../Rating";
import ChevronDown from "../../../assets/icons/navigationIcons/chevron-down-white.svg";

interface CardsCatalogDetailsProps {
  isVip: boolean;
  principalPhoto: string;
  rating: number;
  isVerified: boolean;
  name: string;
  age: string | number;
  profileDetails: string[];
  sendMessageClick: (a: any) => void;
  heartButtonClick: (a: any) => any;
}

const CardsCatalogDetails: React.FC<CardsCatalogDetailsProps> = (props) => {
  const {
    isVip,
    principalPhoto,
    rating,
    name,
    age,
    isVerified,
    profileDetails,
    sendMessageClick,
    heartButtonClick,
  } = props;

  return (
    <div id="image-profile-details">
      <div className="image-profile-details-main">
        {isVip && (
          <div className="vip-tag-container">
            <div className="vip-tag" />
            <p>VIP</p>
          </div>
        )}
        <img src={principalPhoto} alt="profile" />
        <div className="footer-profile-details">
          <div className="interaction-buttons">
            <Button buttonStyle="quintenary" onClick={sendMessageClick}>
              SEND MESSAGE
            </Button>
            <ButtonIcon
              onClick={heartButtonClick}
              buttonStyle="heart-white-gradient"
            />
          </div>
          <div className="footer-profile-divider" />

          <div className="profile-details-container">
            <div className="profile-details-credentials">
              <div className="profile-details-credentials-text">
                <p className="profile-details-name">{name},</p>
                <p className="profile-details-age">{age}</p>
                {isVerified && <img src={Verify} alt="verified icon" />}
              </div>

              <Rating rating={rating} />
            </div>

            <div className="profile-details-details-pills">
              {profileDetails.map((detail, index) => (
                <div className="detail-pill background-blur-gray" key={index}>
                  {detail}
                </div>
              ))}
            </div>
            <img src={ChevronDown} alt="chevron-bottom" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardsCatalogDetails;
