import { useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import CheckIcon from "../../assets/icons/check-circle-green.svg";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";

const ResetPasswordPage: React.FC = () => {
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState("");

  const loginSubmit = () => {};

  const cancelSubmit = () => {};

  return (
    <LayoutDefault className="reset-password">
      <div>
        <Header
          headerIcon="roses-logo"
          backButtonClick={() => {}}
          title={"Reset Password"}
          subTitle={
            <>
              Your password should be at least <br /> 8 characters long
            </>
          }
        />
        <InputText
          value={newUserPassword}
          placeholder="New Password"
          onChangeInput={(e) => setNewUserPassword(e)}
          iconRight={CheckIcon}
        />
        <InputText
          value={newUserConfirmPassword}
          placeholder="Confirm New Password"
          onChangeInput={(e) => setNewUserConfirmPassword(e)}
        />
        <SubText icon={LockIcon} altIcon="clock icon">
          Never share your credentials with anyone.
        </SubText>
      </div>

      <ButtonContainer>
        <Button buttonStyle="primary" onClick={loginSubmit}>
          Reset password
        </Button>
        <Button buttonStyle="quaternary" onClick={cancelSubmit}>
          Cancel
        </Button>
      </ButtonContainer>
    </LayoutDefault>
  );
};

export default ResetPasswordPage;
