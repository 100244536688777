import React, { useState } from "react";
import "./styles.scss";

const ButtonSafety: React.FC = () => {
  const [isPressed, setIsPressed] = useState(false);

  const handlePressStart = () => {
    setIsPressed(true);
  };

  const handlePressEnd = () => {
    setIsPressed(false);
    console.log("Botão solto!");
  };

  return (
    <div
      className="button-border"
      onMouseDown={handlePressStart}
      onMouseUp={handlePressEnd}
      onMouseLeave={() => setIsPressed(false)}
      onTouchStart={handlePressStart}
      onTouchEnd={handlePressEnd}
    >
      <div className="button-inside-border">
        <div className="button-inner">
          <span className="button-text">
            <div>Hold</div>
            <div>Until Safe</div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ButtonSafety;
