import { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import "./styles.scss";
import EyeIcon from "../../assets/icons/eye-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const CreateLegalNamePage: React.FC = () => {
  const [userLegalName, setUserLegalName] = useState("");
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();
  const { setPreRegisterInfo } = useAuth();

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const addLegalNameSubmit = () => {
    setPreRegisterInfo((oldState) => ({ ...oldState, userLegalName: "" }));
    setPrincipalScreen("/create-password");
  };

  useEffect(() => {
    setIsValid(userLegalName.length > 0);
  }, [userLegalName]);

  return (
    <LayoutDefault className="create-name">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => setPrincipalScreen("/welcome-user")}
          title="Legal name"
          subTitle={`We safeguard our community by ensuring the authenticity 
						of every member on our platform, upholding a standard of trust
						and transparency.`}
        />
        <InputText
          value={userLegalName}
          placeholder="Legal Name (as shown on document)"
          onChangeInput={(e) => setUserLegalName(e)}
        />
        <SubText icon={EyeIcon} altIcon="clock icon">
          We’ll never share your legal name
        </SubText>
      </div>

      <ButtonContainer>
        <Button
          disabled={!isValid}
          buttonStyle={isValid ? "primary" : "secondary"}
          onClick={addLegalNameSubmit}
        >
          Continue
        </Button>
      </ButtonContainer>
    </LayoutDefault>
  );
};

export default CreateLegalNamePage;
