import React, { ReactNode } from "react";
import "./styles.scss";
import BackgroundComponentLP from "../../Wrappers/BackgroundComponentLP";
import Header from "../../Headers/Header";
import Button from "../../Buttons/Button";
import Category from "../../Category";
import TranslatableText from "../../Texts/TranslatableText";
import HeaderFeature from "../../Headers/HeaderFeature";

interface CardsFeaturesLPProps {
  buttonText?: ReactNode;
  buttonClick?: (a: any) => void;
  theme: "engagement" | "userContent" | "comprehensiveDashboard";
}

const CardsFeaturesLP: React.FC<CardsFeaturesLPProps> = (props) => {
  const { buttonClick, buttonText, theme } = props;

  const engagement = [
    {
      id: 0,
      icon: "image",
      title: "4/featureTitle-01",
      subTitle: "4/featureDescription-01",
    },
    {
      id: 1,
      icon: "video",
      title: "4/featureTitle-02",
      subTitle: "4/featureDescription-02",
    },
    {
      id: 2,
      icon: "lingerie",
      title: "4/featureTitle-03",
      subTitle: "4/featureDescription-03",
    },
  ];

  const userContent = [
    {
      id: 0,
      icon: "roses-hollow",
      title: "4/featureTitle-04",
      subTitle: "4/featureDescription-04",
    },
    {
      id: 1,
      icon: "percent",
      title: "4/featureTitle-05",
      subTitle: "4/featureDescription-05",
    },
    {
      id: 2,
      icon: "crypto",
      title: "4/featureTitle-06",
      subTitle: "4/featureDescription-06",
    },
  ];

  const comprehensiveDashboard = [
    {
      id: 0,
      icon: "grid",
      title: "4/featureTitle-07",
      subTitle: "4/featureDescription-07",
    },
    {
      id: 1,
      icon: "fire",
      title: "4/featureTitle-08",
      subTitle: "4/featureDescription-08",
    },
    {
      id: 2,
      icon: "star",
      title: "4/featureTitle-09",
      subTitle: "4/featureDescription-09",
    },
  ];

  const cardDetailsHandler = () => {
    switch (theme) {
      case "userContent":
        return userContent;
      case "comprehensiveDashboard":
        return comprehensiveDashboard;
      case "engagement":
        return engagement;
      default:
        return [];
    }
  };

  return (
    <div id="roses-features">
      {cardDetailsHandler().map((features) => (
        <BackgroundComponentLP key={features.id}>
          <HeaderFeature
            hasGapBetweenTitleAndSub={false}
            marginTop={0}
            headerIcon={features.icon as any}
            title={<TranslatableText textKey={features.title} />}
            subTitle={<TranslatableText textKey={features.subTitle} />}
          />
          {theme === "engagement" && features.id === 2 && (
            <div className="pill-engagement">
              <Category>
                <TranslatableText textKey="4/featureComingSoon" />
              </Category>
            </div>
          )}
        </BackgroundComponentLP>
      ))}
      {buttonText && buttonClick && (
        <Button onClick={buttonClick} buttonStyle="primary">
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default CardsFeaturesLP;
