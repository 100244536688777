import { servicesApi } from './services-api';

const ipInfoApi = () => {
	const apiRoute = servicesApi('ipinfo');
	const token = 'secret-ec4c708c-0ab8-436b-b0cd-6a8730838352';
	const authorizationHeader = `Bearer ${token}`;

	return fetch(apiRoute, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: authorizationHeader,
		},
	})
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then(data => {
			return data;
		})
		.catch(error => {
			console.error('There was a problem with the fetch operation:', error);
		});
};

export default ipInfoApi;
