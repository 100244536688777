import React, { useRef, useState } from "react";
import "./styles.scss";

interface CodeVerificationProps {
  fieldsNumber?: number;
  valuesCodesCallback: (a: string) => void;
  isWarning?: boolean;
  warningText?: string;
}

const CodeVerification: React.FC<CodeVerificationProps> = (props) => {
  const {
    fieldsNumber = 5,
    valuesCodesCallback,
    isWarning,
    warningText = "",
  } = props;
  const [fields, setFields] = useState<string[]>(Array(fieldsNumber).fill(""));
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const handleChange = (index: number, value: string) => {
    const updatedFields = [...fields];
    updatedFields[index] = value;
    setFields(updatedFields);

    if (index < inputsRef.current.length - 1 && value) {
      inputsRef.current[index + 1].focus();
    }

    valuesCodesCallback(updatedFields.join(""));
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && fields[index] === "") {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
        const updatedFields = [...fields];
        updatedFields[index - 1] = "";
        setFields(updatedFields);
        valuesCodesCallback(updatedFields.join(""));
      }
    }
  };

  return (
    <div id="code-verification">
      {fields.map((field, index) => (
        <input
          className={isWarning ? "warning-input" : ""}
          key={index}
          placeholder="-"
          type="tel"
          value={field}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          maxLength={1}
          ref={(el) => (inputsRef.current[index] = el as HTMLInputElement)}
        />
      ))}
      {warningText && <p className="warning-text">{warningText}</p>}
    </div>
  );
};

export default CodeVerification;
