import React, { ReactNode, useState } from "react";
import "./styles.scss";
import ListItem from "../../Lists/ListItem";
import CenterText from "../../Texts/CenterText";
import hideIconGradient from "../../../assets/icons/hide-icon-gradient.svg";

interface ImageToggleProps {
  imageOn: string;
  imageOff: string;
  iconLeftContent?: string;
  labelContent: ReactNode;
  descriptionContent: ReactNode;
  showIconLeft: boolean;
  showModal?: boolean;
  borderRadiusSide?: "left" | "right";
}

const ImageToggle: React.FC<ImageToggleProps> = ({
  imageOn,
  imageOff,
  iconLeftContent,
  labelContent,
  descriptionContent,
  showIconLeft,
  showModal,
  borderRadiusSide,
}) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div id="image-toggle-container">
      <img
        src={imageOff}
        className={`toggle-image ${borderRadiusSide} ${
          isToggled ? "hidden" : "visible"
        }`}
        alt="person Off"
      />
      <img
        src={imageOn}
        className={`toggle-image ${borderRadiusSide} ${
          isToggled ? "visible" : "hidden"
        }`}
        alt="person On"
      />
      <div className={`shield-modal ${showModal && isToggled ? "show" : ""}`}>
        <div className="modal-texts">
          <img src={hideIconGradient} />
          <p className="title-modal" textkey="5/locationModalTitle">
            Profile Link
          </p>
          <p className="subtitle-modal" textkey="5/locationModalDescription">
            Share profile link with users
          </p>
          <a href="#" className="profile-link">
            https://roses.vip/username
          </a>
        </div>
      </div>
      <ListItem
        showIconLeft={showIconLeft}
        iconLeftContent={iconLeftContent}
        showIconBorderLeft
        showLabel
        labelContent={labelContent}
        showDescription
        descriptionContent={descriptionContent}
        showToggle
        onToggleChange={handleToggleChange}
      />
    </div>
  );
};

export default ImageToggle;
