import { servicesApi } from "./services-api";

export type PhoneChecker = ReturnType<
  ReturnType<typeof VerifyPhoneWpp>["createVerification"]
>;

const VerifyPhoneWpp = () => {
  const wppRoute = servicesApi("wpp");
  const wppVerifyRoute = servicesApi("wppVerify");

  const createVerification = (phone: string) => {
    const requestCode = () =>
      fetch(`${wppRoute}`, {
        method: "POST",
        body: JSON.stringify({ phone }),
        headers: {
          ["Content-Type"]: "application/json",
        },
      });

    const verifyCode = (code: string) =>
      fetch(`${wppVerifyRoute}`, {
        method: "POST",
        body: JSON.stringify({ phone, code }),
        headers: {
          ["Content-Type"]: "application/json",
        },
      });

    const getPhone = () => phone;

    return {
      requestCode,
      verifyCode,
      getPhone,
    };
  };

  return {
    createVerification,
  };
};

export default VerifyPhoneWpp();
