import { servicesApi } from "./services-api";

const MembershipReferralListApi = async (userId: string) => {
  const apiRoute = `${servicesApi("applicationUserInfo")}${userId}/referrals`;
  const token = "secret-ec4c708c-0ab8-436b-b0cd-6a8730838352";
  const authorizationHeader = `Bearer ${token}`;

  try {
    const response = await fetch(apiRoute, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorizationHeader,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export default MembershipReferralListApi;
