import { useState } from "react";
import "./styles.scss";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import PageTitle from "../../components/PageTitles";
import ButtonContainer from "../../components/Wrappers/ButtonsContainer";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsChangePasswordPage: React.FC = () => {
  const [userPassword, setUserPassword] = useState("");
  console.log(userPassword);

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const resetPasswordSubmit = () => {};

  const cancelSubmit = () => {};

  return (
    <div className="create-password">
      <div>
        <PageTitle
          title={"Password & security"}
          subtitle={"Account Security"}
          hasCloseIcon={false}
          onBackClick={() => setPrincipalScreen("/user-settings-account")}
        />
        <SubText>Your password should be at least 8 characters long</SubText>
        <InputText
          value={userPassword}
          placeholder="Current Password"
          onChangeInput={(e) => setUserPassword(e)}
        />
        <div className="separator" />
        <div className="password-container">
          <InputText
            value={userPassword}
            placeholder="New Password"
            onChangeInput={(e) => setUserPassword(e)}
          />
          <InputText
            value={userPassword}
            placeholder="Confirm New Password"
            onChangeInput={(e) => setUserPassword(e)}
          />
        </div>
        <SubText icon={LockIcon} altIcon="clock icon">
          Never share your credentials with anyone.
        </SubText>
      </div>
      <ButtonContainer>
        <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
          Reset Password
        </Button>
        <Button buttonStyle="quaternary" onClick={cancelSubmit}>
          Cancel
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default UserSettingsChangePasswordPage;
