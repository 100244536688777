import { servicesApi } from "./services-api";

export type PhoneChecker = ReturnType<
  ReturnType<typeof VerifyPhoneSMS>["createVerification"]
>;

const VerifyPhoneSMS = () => {
  const smsRoute = servicesApi("sms");
  const smsVerifyRoute = servicesApi("smsVerify");

  const createVerification = (phone: string) => {
    const requestCode = () =>
      fetch(`${smsRoute}`, {
        method: "POST",
        body: JSON.stringify({ phone }),
        headers: {
          ["Content-Type"]: "application/json",
        },
      });

    const verifyCode = (code: string) =>
      fetch(`${smsVerifyRoute}`, {
        method: "POST",
        body: JSON.stringify({ phone, code }),
        headers: {
          ["Content-Type"]: "application/json",
        },
      });

    const getPhone = () => phone;

    return {
      requestCode,
      verifyCode,
      getPhone,
    };
  };

  return {
    createVerification,
  };
};

export default VerifyPhoneSMS();
