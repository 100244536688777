import React, { ReactNode } from "react";
import "./styles.scss";

interface ButtonContainerProps {
  children: ReactNode;
  className?: string;
}

const ButtonContainer: React.FC<ButtonContainerProps> = (props) => {
  const { children, className } = props;
  return (
    <div id="button-container" className={className}>
      {children}
    </div>
  );
};

export default ButtonContainer;
