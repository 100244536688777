import { servicesApi } from "./services-api";

export type CreateUserProps = ReturnType<
  ReturnType<typeof CreateUser>["postCreateUser"]
>;

const CreateUser = () => {
  const apiRoute = `${servicesApi("userRegister")}/register`;

  const postCreateUser = async (data: {
    displayname: string;
    phone: string;
    email: string;
    password: string;
    gender: string;
    preferedGenders: string[];
  }) => {
    try {
      const response = await fetch(`${apiRoute}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const jsonData = await response.json();

      return {
        status: response.status,
        data: jsonData,
      };
    } catch (error) {
      return {
        status: "error",
        //@ts-ignore
        message: error.message,
      };
    }
  };

  return {
    postCreateUser,
  };
};

export default CreateUser();
