import React from "react";
import "./styles.scss";

interface StoriesProps {
  clickStory: (userId: string) => void;
  className?: string;
  storiesImages: StoriesDetails[];
}

interface StoriesDetails {
  img: string;
  userId: string;
  wasSeen: boolean;
}

const Stories: React.FC<StoriesProps> = ({
  clickStory,
  className,
  storiesImages,
}) => {
  console.log(storiesImages);
  return (
    <div id="stories-component" className={className}>
      {storiesImages.map((story, index) => (
        <div
          key={index}
          className="story-wrapper"
          onClick={() => clickStory(story.userId)}
        >
          <img
            className={`story-image ${
              story.wasSeen ? "new-story-available" : "story-was-seen"
            }`}
            src={story.img}
            alt="stories"
          />
        </div>
      ))}
    </div>
  );
};

export default Stories;
